import EventCard from "./EventCard";
import React, { useState, useEffect } from "react";
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Location } from './Location.svg';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { informationWithPastEvents, loggedInUser, loggedInEmail, userInfo } from './information';
import addresses from './information';
import Footer from './Footer';
import Attendees from "./Attendees";
import axios from "axios";
import ShareButton from "./ShareButton.js";
import RegisterModal from "./RegisterModal.js";
import EditRSVP from "./EditRSVP.js";
import SignUp from "./SignUp.js";
import OrganizerNavbar from "./OrganizerNavbar.js";

const secrets = require("./secrets.js");
const api = secrets.server_url;

const Profile = () => {
  const [user, setUser] = useState(loggedInUser.data);
  const [email, setEmail] = useState(loggedInEmail.data);
  const [userDetails, setUserDetails] = useState(userInfo ? userInfo : null);
  const [loading, setLoading] = useState(false);

  function createCard(info) {
    return (
      <Link key={info._id} to={`/event/${info._id}`} className="card-link">
        <EventCard
          imgurl={info.img}
          event={info.name}
          masjid={info.organization}
          date={info.dateIndex == -1 ? info.dates[0] : info.dates[info.dateIndex]}
          altdate={info.timeArray[0]}
          description={info.description}
          age={info.age_range}
          frequency={info.frequency}
          price={info.price}
          gender={info.gender}
          registered={info.registered}
          time={info.start_datetime}
          tags={info.tags}
        />
      </Link>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetails) {
    return <div>Error loading user details.</div>;
  }

  const searchIds = (userDetails && userDetails.signed_up_events) 
    ? userDetails.signed_up_events.filter(event => !isNaN(event)) 
    : [];
    
  const searchCreatedIds = (userDetails && userDetails.createdEvents) 
    ? userDetails.createdEvents.filter(event => !isNaN(event)) 
    : [];
    
  const searchNames = (userDetails && userDetails.signed_up_events) 
    ? userDetails.signed_up_events
        .filter(event => isNaN(event))
        .map(name => name.trim().toLowerCase()) 
    : [];

  function filterEventsByIds(events, searchIds) {
    return events.filter(event => searchIds.includes(event.event_id.toString()));
  }

  const filteredEventsByIds = filterEventsByIds(informationWithPastEvents, searchIds);
  const filteredCreatedEvents = filterEventsByIds(informationWithPastEvents, searchCreatedIds);

  return (
    <>
      <div className="wrapper">
      {loggedInUser.data === "Enterprise User" ? (
                <OrganizerNavbar />
            ) : (
                <Navbar />
            )}
        <div className="profile-container">
          <div className="profile-header">
            <svg className="profile-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </g>
            </svg>
            <div className="profile-details">
              <h2>{userDetails?.username || 'User'}</h2>
              <p>{userDetails?.signed_up_events?.length || 0} Events signed up</p>
            </div>
          </div>
          <div className="profile-hosting">
            <h2 className="registered-events-title">Registered Events</h2>
            {filteredEventsByIds.map(createCard)}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;