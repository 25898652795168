import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import './Events';
import { information, loggedInUser, loggedInEmail, isAdmin, selectedOrganization, selectedOrganizationInfo, informationWithPastEvents, userInfo } from './information';
import OrganizerNavbar from './OrganizerNavbar.js';
import axios from 'axios';
import EventCard from './EventCard.js';
import { ReactComponent as Avatar } from "./IconUmmahfy.svg";
import { uploadImage } from './Utilities/s3Handler';
import { Segmented, Alert } from 'antd';
import NoFlyer from './NoFlyer1.png';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactComponent as NoEvents } from './NoEvents.svg';
import { ReactComponent as Edit } from './Edit.svg';
import { Users, MapPin, Laptop } from "lucide-react"

const secrets = require('./secrets');
const api = secrets.server_url;

console.log(selectedOrganizationInfo)

function OrganizerHome() {
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(selectedOrganizationInfo.description || "");
  const [editedLocation, setEditedLocation] = useState(selectedOrganizationInfo.location || "123 Example St, Example City, State 00000");
  const [value, setValue] = useState('Upcoming');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const events = informationWithPastEvents.filter(infoEvent =>
    selectedOrganizationInfo.events.includes(infoEvent.event_id.toString())
  );

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const pastEvents = informationWithPastEvents.filter(infoEvent =>
    selectedOrganizationInfo.events.includes(infoEvent.event_id.toString()) &&
    (new Date(infoEvent.start_datetime) < currentDate || infoEvent.past === true)
  );

  const upcomingEvents = informationWithPastEvents.filter(infoEvent => {
    const eventStartDate = new Date(infoEvent.dates[0]);

    return selectedOrganizationInfo.events.includes(infoEvent.event_id.toString()) &&
      eventStartDate >= currentDate &&
      infoEvent.past !== true;
  });

  const handleSwitchOrg = async (orgName) => {
    console.log('Clicked item:', orgName);
    const response = await axios.post(`${api}switchSelectedOrganization/`, {
      org: orgName
    }, {
      withCredentials: true,
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    });
    window.location.reload()
  };

  function createCard(info) {
    return (
      <Link key={info._id} to={`/event/${info._id}`} className="card-link">
        <EventCard
          imgurl={info.img}
          event={info.name}
          masjid={info.organization}
          date={info.timeArray[0]}
          altdate={info.dateIndex == -1 ? info.dates[0] : info.dates[info.dateIndex]}

          description={info.description}
          age={info.age_range}
          frequency={info.frequency}
          price={info.price}
          gender={info.gender}
          registered={info.registered}
          time={info.start_time}
          tags={info.tags}
        />
      </Link>
    );
  }

  const uploadProfilePicture = async (imgFile) => {
    setIsUploading(true);
    setUploadError(null);
    try {
      const img_url = await uploadImage(imgFile);
      const response = await axios.put(`${api}organizations/`, {
        profilePicture: img_url
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        },
        params: {
          name: selectedOrganizationInfo.name
        }
      });

      if (response.status === 200) {
        selectedOrganizationInfo.profilePicture = img_url;
        setIsUploading(false);
        // alert('Profile picture updated successfully!');
        <Alert message="Profile picture updated successfully!" type="success" />;

      } else {
        throw new Error('Failed to update profile picture');
      }
    } catch (err) {
      console.error(err);
      setUploadError('Failed to upload profile picture. Please try again.');
      setIsUploading(false);
    }
  }

  const uploadBannerPicture = async (imgFile) => {
    setIsUploading(true);
    setUploadError(null);
    try {
      const img_url = await uploadImage(imgFile);
      const response = await axios.put(`${api}organizations/`, {
        bannerPicture: img_url
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        },
        params: {
          name: selectedOrganizationInfo.name
        }
      });

      if (response.status === 200) {
        selectedOrganizationInfo.bannerPicture = img_url;
        setIsUploading(false);
        // alert('Banner picture updated successfully!');
        <Alert message="Banner picture updated successfully!" type="success" />;

      } else {
        throw new Error('Failed to update banner picture');
      }
    } catch (err) {
      console.error(err);
      setUploadError('Failed to upload banner picture. Please try again.');
      setIsUploading(false);
    }
  }

  const updateDescription = async (description) => {
    try {
      const response = await axios.put(`${api}organizations/`, {
        description: description
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        },
        params: {
          name: selectedOrganizationInfo.name
        }
      })
      selectedOrganizationInfo.description = description;
    }
    catch (err) {
      console.log(err)
    }
  }

  const updateLocation = async (location) => {
    try {
      const response = await axios.put(`${api}organizations/`, {
        location: location
      }, {
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        },
        params: {
          name: selectedOrganizationInfo.name
        }
      })
      selectedOrganizationInfo.location = location;
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleSave = async () => {
    if (isEditing) {
      if (editedDescription) {
        await updateDescription(editedDescription);
      }
      if (editedLocation) {
        await updateLocation(editedLocation);
      }
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
  ];

  return (
    <>
      {console.log(loggedInUser.data, "tryna figure out if user is enterprise user")}
      <div className="wrapper">
        <OrganizerNavbar />

        <div className="settings-wrapper">
          <div className="profile-card">
            {!isEditing && (
              <button onClick={handleSave} className="org-edit-btn-new">
                <Edit className="edit-icon" />
              </button>
            )}
            <div className={`org-banner ${isEditing ? 'editable' : ''}`}>
              {selectedOrganizationInfo.bannerPicture && (
                <img src={selectedOrganizationInfo.bannerPicture} alt="Organization Banner" className="org-banner-img" />
              )}
              {isEditing && (
                <input
                  type="file"
                  onChange={(e) => uploadBannerPicture(e.target.files[0])}
                  className="org-banner-upload"
                />
              )}
            </div>

            <div className="org-content">
              <div className={`org-profile-pic-container ${isEditing ? 'editable' : ''}`}>
                {selectedOrganizationInfo.profilePicture ? (
                  <img src={selectedOrganizationInfo.profilePicture} alt="Profile" className="org-profile-img" />
                ) : (
                  <Avatar className="org-profile-img" />
                )}
                {isEditing && (
                  <input
                    type="file"
                    onChange={(e) => uploadProfilePicture(e.target.files[0])}
                    className="org-prof-pic-upload"
                  />
                )}
              </div>

              <div className="prof-title-edit">
                <h1 className="org-title">{selectedOrganization}</h1>

              </div>

              {isEditing ? (
                <input
                  value={editedLocation}
                  onChange={(e) => setEditedLocation(e.target.value)}
                  className="org-address"
                />
              ) : (
                <div class="org-profile-address">
                  {/* <MapPin className='org-location-icon'/> */}
                  <p className="org-address">{editedLocation}</p>
                </div>
              )}

              {isEditing ? (
                <ReactQuill
                  theme="snow"
                  className="my-custom-quill profile-desc"
                  modules={modules}
                  formats={formats}
                  value={editedDescription}
                  onChange={setEditedDescription}
                  placeholder="Enter your organization's description here..."
                />
              ) : (
                <div className="org-description">
                  {editedDescription ? (
                    <div dangerouslySetInnerHTML={{ __html: editedDescription }} />
                  ) : (
                    <p className="description-placeholder">
                      Welcome to your organization page! We're excited to share your story and mission.
                      Click the pencil icon button on the right to add a profile picture, profile banner, location, and
                      description to tell the community about who you are and what you do.
                    </p>
                  )}
                </div>
              )}
            </div>

            {isEditing && (
              <button onClick={handleSave} className="org-edit-btn org-save-btn">
                Save
              </button>
            )}
          </div>

          {isUploading && <p>Uploading...</p>}
          {uploadError &&
            <Alert
              message={uploadError}
              type="error"
              showIcon
              closable
              onClose={() => setUploadError(null)}  // Assuming you're using setUploadError to update the error state
              style={{ marginTop: '8px' }}  // Optional: adds some spacing
            />
          }

          <div className="org-title-toggle">
            <h1 className="org-events-title">My Events</h1>
            <Segmented
              size="large"
              className="org-toggle"
              options={['Upcoming', 'Past']}
              value={value}
              onChange={setValue}
            />
          </div>

          {value === 'Upcoming' ? (
            upcomingEvents.length > 0 ? (
              upcomingEvents.map(createCard)
            ) : (
              <div className="no-events-message">
                <NoEvents className="prof-no-events" />
              </div>
            )
          ) : (
            pastEvents.length > 0 ? (
              pastEvents.map(createCard)
            ) : (
              <div className="no-events-message">
                <NoEvents className="prof-no-events" />
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OrganizerHome;