import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import HomeCard from './HomeCard';
import './Events';
import { useNavigate } from 'react-router-dom';
import Signin from './Signin';
import SignUp from './SignUp';
import { ReactComponent as PlusIcon } from './PlusIcon.svg';
import rope from "./rope.png"
import AddCard from './AddCard';
import HeroImage from './HeroImage.png';
import Ummahfy from './Ummahfy.svg';
import timeArray from './information.js';
import { information, loggedInUser, loggedInEmail, isAdmin, userLoc } from './information';
import OrganizerNavbar from './OrganizerNavbar.js';

// import information from './information';
// import getAllEvents from './information';
// const information = require("./information.js");
//import { getAllEvents } from "./information.js";



// //console.log("----------------------")
// //console.log(information.information)

// const isUrl = (string) => {
//   try {
//       new URL(string);
//       return true;
//   } catch (err) {
//       return false;
//   }
// };
function Home() {

  const [buttonSignin, setButtonSignin] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(loggedInUser.data);

  let info

  if(userLoc && userLoc.city) {
      console.log("heebee")
      const infoWithinRad = information.filter(item => item.location && item.distanceFromUser < 25);
      if(infoWithinRad.length >= 4){
        info = infoWithinRad
      } else {
        info = information
      }
      console.log(info)
  }
  else{
    info = information
  }





  function createCard(info) {
    // const baseURL = 'http://localhost:4000/api/uploads/'; // Adjust this base URL to your backend's static file serving path
    // const imgURL = isUrl(info.img) ? info.img : `${baseURL}${info.img.split('/').pop()}`;
    console.log(info)
    return (
      <Link key={info._id} to={`/event/${info._id}`} className="card-link" style={{ textDecoration: 'none' }}>
        <HomeCard
          imgurl={info.img}
          event={info.name}
          masjid={info.organization}
          date={info.timeArray[0]}
          tags={info.tags}

          age={info.age_range}
          frequency={info.frequency}
          price={info.price}
          gender={info.gender}
          altdate={info.dateIndex == -1 ? info.dates[0] : info.dates[info.dateIndex]}
          time={info.start_datetime}

        //time={info.time}
        />
      </Link>
    )
  }

  const handleAddCardClick = () => {
    if (user) {
      //console.log("signed in ");
      navigate('/newCreateEvent');
    } else {
      //console.log("not signed in")
      setButtonSignin(true);
    }
  };

  return (
    <>

      <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
      <div className="wrapper">

      {loggedInUser.data === "Enterprise User" ? (
         
         <OrganizerNavbar />
       ) : (
         <Navbar />
       )}


        <div class="hero">
          <div class="text">
            <p class="title"> Unify the Ummah, one event at a time.</p>
            <p class="subtext"> Discover a space where Muslims of all backgrounds unite, celebrate traditions, and build community through local events,
              both in and out of the Masjid. Strengthen your faith and friendships—join Ummahfy today! </p>

            {!user ? (
              <>
                <button className="join" onClick={() => setButtonSignin(true)}>Join Ummahfy</button>
                <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
              </>
            ) : (
              <>
                {/* Add your else condition content here */}
                <Link to="/newCreateEvent">
                  <button className="join">Create an Event</button>
                </Link>

              </>
            )}

          </div>
          {/* <img class= "hero-img" src = "https://cdn.dribbble.com/userupload/10463743/file/original-d18e59fcf28eeef12a0915d845979fee.jpg?resize=1200x1200" /> */}
          <img className="hero-img" src={Ummahfy} alt="Hero" />
        </div>

        <div class="upcoming-wrapper">
          {userLoc && userLoc.city && (information.filter(item => item.distanceFromUser && item.distanceFromUser < 50)).length > 4 ? (
            <p class="upcoming"> Upcoming Events Near {userLoc.city} </p>

          ) : (
            <p class="upcoming"> Upcoming Events </p>

          )}
          <Link to="/events" className="see-all">
            See All Events
          </Link>
        </div>


        <div class="grid-container">
          {info.slice(0, 8).map(createCard)}
        </div>


        <div class="quran-div" >
          <div class="quote-buttons">
            <h1 class="quran-text" >
              “And hold firmly to the rope of Allah all together, and do not become divided...” (Quran 3:103) </h1>
            <div class="quran-btn-group">
              <button class="join quran-join" onClick={() => setButtonSignin(true)}> Join Ummahfy</button>
              <Signin trigger={buttonSignin} setTrigger={setButtonSignin}> </Signin>
              <Link to="/events" className="see-all quran-see-all" >
                See All Events
              </Link>
            </div>
          </div>
          {/* <div class="rope-png" style={{width:'20%', marginRight: '72px'}}>
      <img src={rope} alt="rope" style={{filter:'invert(1)', width: '100%'}}/>  
  </div> */}
        </div>

        <div>


          <div class="want-more">
            <h1 > Want more on Ummahfy? </h1>


            <div class="add-cards">
              <div onClick={handleAddCardClick}>
                <AddCard
                  title="Host Your Own Event"
                  description="Whether you’re a Masjid, Muslim organization, or somebody with an idea, you can create an event!"
                  tagline="Create an event"
                  target=""
                />
              </div>


              <AddCard
                link="https://docs.google.com/forms/d/e/1FAIpQLSdFUSbT1Tik4sonyhxeSS_dr8UqbcOKcyDREwjLqMD5iFu9iA/viewform"
                title="Add Your Organization"
                description="If you want to see your local Masjid or Muslim Organization on Ummahfy, fill out this form!"
                tagline="Request an Organization"
                target="_blank"
              />

            </div>



          </div>


        </div>
      </div>
      <Footer> </Footer>

    </>
  );
}

export default Home;