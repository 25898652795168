import React, { useState, useEffect } from 'react';
import { loggedInUser, loggedInEmail, getEventLoc, userLoc, selectedOrganizationInfo, selectedOrganization } from "./information.js";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FileUploadButton from "./FileUploadButton";
import LocationSearchInput from "./LocationSearchInput";
import axios from 'axios';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ConfirmationModal from './ConfirmationModal.js';
import { uploadImage } from './Utilities/s3Handler';

dayjs.extend(customParseFormat);

// const { RangePicker } = DatePicker;

const secrets = require('./secrets.js');
const api = secrets.server_url;

const recurringOptions = ["N/A", "Daily", "Weekly", "Monthly"];
const ageOptions = ["N/A", "Youth", "HS/College", "Adult"];
const genderOptions = ["N/A", "Male", "Female"];
const organizerOptions = ["Masjid", "Community"];
const priceOptions = ["N/A", "Free", "$", "$$", "$$$"];

function CreateEvent() {
    const [frequency, setFrequency] = useState(""); // null, weekly, monthly, custom
    const [startDate, setStartDate] = useState("");
    const [endDate, sendEndDate] = useState("");
    const [customDatesList, setCustomDatesList] = useState([]);
    const [selectedDays, setSelectedDays] = useState("");
    const [recurrence, setRecurrence] = useState(1);

    // const [date, setDate] = useState("");
    // const [startTime, setStartTime] = useState("");
    // const [endTime, setEndTime] = useState("");

    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 890 ? false : true);
    const [confirmationModal, setConfirmationModal] = useState(false);


    const [eventData, setEventData] = useState({
        name: '',
        organization: loggedInUser.data === "Enterprise User" ? selectedOrganizationInfo.name : loggedInUser.data,
        creator: loggedInEmail.data || "",
        frequency: '',
        dates: [],
        start_time: '',
        end_time: '',
        description: '',
        img: '',
        price: '',
        location: '',
        latitude: '',
        longitude: '',
        age_range: '',
        gender: '',
        isApproved: false,
        capacity: '',
        isPrivate: false,
        requireApproval: false,
        approvedAttendees: [],
        requireTickets: false,
        tags: []
    });


    // useEffect(() => {
    //     setEventData(prevData => ({
    //         ...prevData,
    //         start_datetime: startTime ? `${date}T${startTime}` : date,
    //         end_datetime: endTime ? `${date}T${endTime}` : date
    //     }));
    // }, [date, startTime, endTime]);



    // const onDateChange = (date, dateString) => {
    //     setDate(dateString);
    // };

    // const onStartTimeChange = (time, timeString) => {
    //     if (time) {
    //         const formattedTimeString = time.format('HH:mm');
    //         setStartTime(`${formattedTimeString}:00.000`);
    //     }
    //     else {
    //         setStartTime('')
    //     }

    // };

    // const onEndTimeChange = (time, timeString) => {
    //     if (time) {
    //         const formattedTimeString = time.format('HH:mm');
    //         setEndTime(`${formattedTimeString}:00.000`);
    //     }
    //     else {
    //         setEndTime('')
    //     }
    // };


    //call on submit                                //start date, end date, frequency
    const calculateAllDates = async () => {
        switch (frequency) {
            case '':
                if (startDate === endDate) {
                    setEventDataItem('dates', [startDate])
                } else {
                    setEventDataItem('dates', await getDatesBetween(startDate, endDate))
                }
                break;
            case 'weekly':
                setEventDataItem('dates', await getRecurringDates(startDate, endDate, recurrence, frequency, selectedDays))
                break;
            case 'monthly':
                setEventDataItem('dates', await getRecurringDates(startDate, endDate, recurrence, frequency, selectedDays[0]))
                break;
            case 'custom':
                setEventDataItem('dates', customDatesList)
                break;
            default:
                break;
        }
    };

    async function getDatesBetween(startDateStr, endDateStr) {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        const dateArray = [];

        // Ensure end date is greater than or equal to start date
        if (startDate > endDate) {
            return dateArray; // Return an empty array if dates are in wrong order
        }

        // Create a date pointer starting from the start date
        let currentDate = new Date(startDate);

        // Loop through each day, adding it to the array
        while (currentDate <= endDate) {
            dateArray.push(currentDate.toISOString().split('T')[0]); // Add the current date as a string (YYYY-MM-DD)
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return dateArray;
    }

    
    function getRecurringDates(startDate, endDate, recurrence, freq, daysOfWeek) {
        // Convert input dates to UTC midnight
        const start = new Date(Date.UTC(new Date(startDate).getUTCFullYear(), new Date(startDate).getUTCMonth(), new Date(startDate).getUTCDate()));
        const end = new Date(Date.UTC(new Date(endDate).getUTCFullYear(), new Date(endDate).getUTCMonth(), new Date(endDate).getUTCDate()));
        const dateArray = [];

        if (start > end) return dateArray;

        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const dayIndices = freq === 'Weekly'
            ? daysOfWeek.map(day => dayNames.indexOf(day)).sort((a, b) => a - b)
            : [dayNames.indexOf(daysOfWeek)];

        function addDays(date, days) {
            const result = new Date(date);
            result.setUTCDate(result.getUTCDate() + days);
            return result;
        }

        function getWeekOfMonth(date) {
            const firstDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
            return Math.ceil((date.getUTCDate() + firstDay.getUTCDay() - 1) / 7);
        }

        function getNthDayOfMonth(year, month, n, dayIndex) {
            const firstDay = new Date(Date.UTC(year, month, 1));
            const dayOffset = (dayIndex - firstDay.getUTCDay() + 7) % 7;
            const targetDate = new Date(Date.UTC(year, month, 1 + dayOffset + (n - 1) * 7));
            return targetDate.getUTCMonth() === month ? targetDate : null;
        }

        function getUTCDateString(date) {
            return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
        }

        if (freq === 'Weekly') {
            let current = new Date(start);

            // Find the first occurrence on or after the start date
            while (!dayIndices.includes(current.getUTCDay())) {
                current = addDays(current, 1);
            }

            while (current <= end) {
                if (current >= start) {
                    dateArray.push(getUTCDateString(current));
                }

                // Find the next occurrence
                let daysToAdd = 1;
                while (!dayIndices.includes((current.getUTCDay() + daysToAdd) % 7)) {
                    daysToAdd++;
                }

                current = addDays(current, daysToAdd);

                // Apply recurrence if we've gone through all days in the pattern
                if (dayIndices.indexOf(current.getUTCDay()) === 0) {
                    current = addDays(current, 7 * (recurrence - 1));
                }
            }
        } else if (freq === 'Monthly') {
            const startWeek = getWeekOfMonth(start);
            const dayIndex = dayIndices[0];
            let current = new Date(Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 1));

            while (current <= end) {
                const targetDate = getNthDayOfMonth(current.getUTCFullYear(), current.getUTCMonth(), startWeek, dayIndex);
                if (targetDate && targetDate >= start && targetDate <= end) {
                    dateArray.push(getUTCDateString(targetDate));
                }
                current.setUTCMonth(current.getUTCMonth() + recurrence);
            }
        }

        return dateArray;
    }

    function assertEquals(actual, expected, message) {
        const passed = JSON.stringify(actual) === JSON.stringify(expected);
        console.log(passed ? `PASSED: ${message}` : `FAILED: ${message}`);
        if (!passed) {
            console.log('Expected:', expected);
            console.log('Actual:', actual);
        }
    }

    // Test cases
    function runTests() {
        // Test 1: Monthly recurrence - 2nd Tuesday for 6 months
        assertEquals(
            getRecurringDates('2024-07-09', '2024-12-31', 1, 'Monthly', 'Tuesday'),
            ['2024-07-09', '2024-08-13', '2024-09-10', '2024-10-08', '2024-11-12', '2024-12-10'],
            'Monthly recurrence - 2nd Tuesday for 6 months'
        );

        // Test 2: Monthly recurrence - 3rd Friday for 4 months
        assertEquals(
            getRecurringDates('2024-03-15', '2024-06-30', 1, 'Monthly', 'Friday'),
            ['2024-03-15', '2024-04-19', '2024-05-17', '2024-06-21'],
            'Monthly recurrence - 3rd Friday for 4 months'
        );

        // Test 3: Weekly recurrence - Every Monday and Wednesday for 3 weeks
        assertEquals(
            getRecurringDates('2024-01-01', '2024-01-21', 1, 'Weekly', ['Monday', 'Wednesday']),
            ['2024-01-01', '2024-01-03', '2024-01-08', '2024-01-10', '2024-01-15', '2024-01-17'],
            'Weekly recurrence - Every Monday and Wednesday for 3 weeks'
        );

        // Test 4: Weekly recurrence - Every other Thursday for 2 months
        assertEquals(
            getRecurringDates('2024-02-01', '2024-03-31', 2, 'Weekly', ['Thursday']),
            ['2024-02-01', '2024-02-15', '2024-02-29', '2024-03-14', '2024-03-28'],
            'Weekly recurrence - Every other Thursday for 2 months'
        );

        // Test 5: Monthly recurrence - 5th Wednesday (should skip months without 5th Wednesday)
        assertEquals(
            getRecurringDates('2024-01-31', '2024-12-31', 1, 'Monthly', 'Wednesday'),
            ['2024-01-31', '2024-05-29', '2024-07-31', '2024-10-30'],
            'Monthly recurrence - 5th Wednesday (skipping months without)'
        );

        // Test 6: Monthly recurrence across year boundary
        assertEquals(
            getRecurringDates('2024-11-15', '2025-02-28', 1, 'Monthly', 'Friday'),
            ['2024-11-15', '2024-12-20', '2025-01-17', '2025-02-21'],
            'Monthly recurrence across year boundary'
        );

        // Test 7: Weekly recurrence with start date not on recurrence day
        assertEquals(
            getRecurringDates('2024-03-20', '2024-04-10', 1, 'Weekly', ['Monday']),
            ['2024-03-25', '2024-04-01', '2024-04-08'],
            'Weekly recurrence with start date not on recurrence day'
        );

        // Test 8: Monthly recurrence with end date before next occurrence
        assertEquals(
            getRecurringDates('2024-01-31', '2024-02-27', 1, 'Monthly', 'Wednesday'),
            ['2024-01-31'],
            'Monthly recurrence with end date before next occurrence'
        );

        // Test 9: Weekly recurrence with multiple days including start date
        assertEquals(
            getRecurringDates('2024-06-05', '2024-06-20', 1, 'Weekly', ['Monday', 'Wednesday', 'Friday']),
            ['2024-06-05', '2024-06-07', '2024-06-10', '2024-06-12', '2024-06-14', '2024-06-17', '2024-06-19'],
            'Weekly recurrence with multiple days including start date'
        );

        // Test 10: Invalid input handling
        assertEquals(
            getRecurringDates('2024-01-01', '2023-12-31', 1, 'Monthly', 'Monday'),
            [],
            'Invalid input: End date before start date'
        );

        console.log('All tests completed.');
    }

    // Run the tests
    runTests();


    const onStartTimeChange = (time, timeString) => {
        if (time) {
            const formattedTimeString = time.format('HH:mm');
            setEventData(prevData => ({
                ...prevData,
                ['start_time']: `${formattedTimeString}:00.000`
            }));
        }
        else {
            setEventData(prevData => ({
                ...prevData,
                ['start_time']: ''
            }));

        }
    };

    const onEndTimeChange = (time, timeString) => {
        if (time) {
            const formattedTimeString = time.format('HH:mm');
            setEventData(prevData => ({
                ...prevData,
                ['end_time']: `${formattedTimeString}:00.000`
            }));
        }
        else {
            setEventData(prevData => ({
                ...prevData,
                ['end_time']: ''
            }));

        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // //console.log(name)
        // //console.log(value)
        setEventData(prevData => ({
            ...prevData,
            [name]: value === 'N/A' ? '' : value
        }));
    };

    const setEventDataItem = (name, value) => {
        setEventData(prevData => ({
            ...prevData,
            [name]: value || ''
        }));
    };


    const handlePlaceSelected = async (place) => {
        //console.log(place)
        await setEventData(prevData => ({
            ...prevData,
            location: place.formatted_address
        }))

        if (place) {
            //console.log("calling geteventloc")
            const coords = await getEventLoc(place.formatted_address)
            //console.log("coords:")
            //console.log(coords)
            // //console.log(eventData)
            // //console.log(eventData.location)
            // //console.log(coords)
            setEventData(prevData => ({
                ...prevData,
                latitude: coords.latitude
            }));
            setEventData(prevData => ({
                ...prevData,
                longitude: coords.longitude
            }));
        }

    };

    const handleFileSelect = async (file) => {
        setEventData(prevData => ({
            ...prevData,
            img: file
        }));
    };

    // const fetchSessionEmail = async () => {
    //     const response = await axios.get(`${api}getLoggedInEmail/`, {
    //       withCredentials: true, // Include cookies with requests
    //     });
    //     return response.data;
    // };

    async function createPaymentLink(event_id) {

        console.log("creating payment link")
        try {
            const response = await axios.post(`${api}stripe/createProductAndPaymentLink`, {
                eventName: eventData.name,
                eventDescription: eventData.description,
                ticketPrice: Number(eventData.price) * 100, // $50.00 in cents
                currency: 'usd',
                organizationId: selectedOrganizationInfo.stripeAccountId, // The ID of the organization creating the event
                eventId: event_id
            });

            if (response.data && response.data.event) {
                console.log('Paid event created successfully:', response.data.event);
            }
        } catch (error) {
            console.error('Error creating paid event:', error);
        }
    }

    const handleCreateEvent = async (e) => {
        e.preventDefault();
        console.log(0)

        if (!eventData.name || !eventData.start_datetime || !eventData.end_datetime || !eventData.location) {
            console.error('Please fill out all required fields.');
            return;
        }

        //console.log("look at this for time value error", eventData);



        setLoading(true);
        try {
            // const formData = new FormData();
            // for (const key in eventData) {
            //     formData.append(key, eventData[key]);
            // }

            const img_url = await uploadImage(eventData.img)
            eventData.img = img_url
            
            eventData.dates = calculateAllDates()
            eventData.frequency = frequency

            const response = await axios.post(`${api}events/`, eventData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            })
            console.log(response)
            console.log(1)
            if (loggedInUser.data === "Enterprise User") {
                console.log(2)
                // add event id to organization's events
                console.log(response)
                const updatedEventsArr = selectedOrganizationInfo.events
                updatedEventsArr.push(response.data.data.event_id)

                const response2 = await axios.put(`${api}organizations/`, {
                    events: updatedEventsArr
                }, {
                    params: {
                        name: selectedOrganization
                    },
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                    }
                })

                console.log("pushed eventid to org events arr")



                //create payment link
                if (!isNaN(eventData.price) && eventData.price > 0) {
                    console.log("price is a number")
                    if (!selectedOrganizationInfo.stripeAccountId || selectedOrganizationInfo.stripeAccountStatus === "pending") {
                        console.log("stripe account not set up or verification still pending")
                    }
                    else {
                        console.log(response.data.data.event_id)
                        await createPaymentLink(response.data.data.event_id)
                    }
                }

            }
            console.log(3)
            console.log(window.location.href)
            //send confirmation email
            if (loggedInEmail.data) {
                const payload = {
                    email: loggedInEmail.data,
                    eventName: eventData.name,
                    type: "Create Event"
                };

                try {
                    const response = await axios.post(`${api}sendEmail`, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        }
                    });
                    console.log('Response:', response.data);
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
            }

            setLoading(false);

            //console.log('Event created successfully:', response.data);
            setConfirmationModal(true)
            console.log("after")
            // Handle success (e.g., redirect or clear form)
        } catch (error) {
            console.error('Error creating event:', error);
        } finally {

        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
            <div className="wrapper">
                <Navbar />
                <div className="ce-hero">
                    <div className="ce-text">
                        <p className="ce-title">Create Your Event Here!</p>
                        <p className="subtext ce-subtext">
                            Bringing the community together has never been easier! Organize and share your events with fellow members of the Ummah, and find your tribe of people who share your interests. Just fill out the form below!
                        </p>
                    </div>
                </div>

                <form onSubmit={handleCreateEvent} onKeyDown={handleKeyDown}>
                    <div className="create-event-container">
                        <div className="grid-block-one">
                            <div className="event-name">
                                <label htmlFor="name">Event Name</label>
                                <input
                                    type="text"
                                    id="event"
                                    name="name"
                                    className="ce-input"
                                    value={eventData.name}
                                    onChange={handleChange}
                                    placeholder='Event Name Here'
                                    autoComplete="off"
                                    required
                                />
                            </div>

                            <div className="location">
                                <label htmlFor="location">Location</label>
                                <LocationSearchInput
                                    id="location"
                                    name="location"
                                    currentLoc={userLoc}
                                    onPlaceSelected={handlePlaceSelected}
                                    required
                                />
                            </div>


                            <div className="date-input">
                                <label htmlFor="start_datetime">Date</label>
                                <DatePicker className="date-picker" required />
                                {/* <RangePicker className="date-picker" onChange={onDateChange} required /> */}

                            </div>

                            <div className="short1">
                                <label htmlFor="time">Start Time</label>
                                <TimePicker inputReadOnly={isMobile} use12Hours format="h:mm a" className="time-picker" onChange={onStartTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} required />
                            </div>

                            <div className="short2">
                                <label htmlFor="extra"> End Time</label>
                                <TimePicker inputReadOnly={isMobile} use12Hours format="h:mm a" className="time-picker" onChange={onEndTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} required />
                            </div>

                            <div className="cost-input">
                                <label htmlFor="price">Cost</label>
                                <input
                                    type="number"
                                    maxLength="8"
                                    id="cost"
                                    name="price"
                                    className="ce-input"
                                    value={eventData.price}
                                    onChange={handleChange}
                                    placeholder='$0.00'
                                    autoComplete="off"
                                    required
                                />
                            </div>

                            <div className="capacity-input">
                                <label htmlFor="capacity">Capcity</label>
                                <input
                                    type="number"
                                    maxLength="8"
                                    id="capacity"
                                    name="capacity"
                                    className="ce-input"
                                    value={eventData.capacity}
                                    onChange={handleChange}
                                    placeholder='50 guests'
                                    autoComplete="off"
                                    required
                                />
                            </div>

                            <div className="upload">
                                <label>Upload Your Flyer</label>
                                <FileUploadButton onFileSelect={handleFileSelect} />
                            </div>

                            <div className="description">
                                <label htmlFor="description">Description</label>
                                <textarea
                                    className="textArea-input"
                                    id="description"
                                    name="description"
                                    value={eventData.description}
                                    onChange={handleChange}
                                    placeholder='Write your Description Here'
                                    required
                                />
                            </div>
                        </div>

                        <div className="grid-block-two">
                            <div className="tag-selection">
                                <label className="tag-label">
                                    Select Tags <p style={{ fontWeight: '400', color: '#333', marginLeft: '4px' }}>(if applicable)</p>
                                </label>
                                <div className="filter-tag">
                                    <div className="tag-content">
                                        <div className="group-box">
                                            <p className="tag-groups">Organizers</p>
                                            <div className="chbx-group">
                                                {organizerOptions.map((option, index) => (
                                                    <label key={index} htmlFor={`organization-${index}`} className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            id={`organization-${index}`}
                                                            name="org_type"
                                                            value={option}
                                                            onChange={handleChange}

                                                        />
                                                        <span className="c-label-r">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="group-box">
                                            <p className="tag-groups">Age</p>
                                            <div className="chbx-group">
                                                {ageOptions.map((option, index) => (
                                                    <label key={index} htmlFor={`age_range-${index}`} className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            id={`age_range-${index}`}
                                                            name="age_range"
                                                            value={option}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="c-label-r">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="group-box">
                                            <p className="tag-groups">Gender</p>
                                            <div className="chbx-group">
                                                {genderOptions.map((option, index) => (
                                                    <label key={index} htmlFor={`gender-${index}`} className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            id={`gender-${index}`}
                                                            name="gender"
                                                            value={option}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="c-label-r">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="group-box">
                                            <p className="tag-groups">Recurring</p>
                                            <div className="chbx-group">
                                                {recurringOptions.map((option, index) => (
                                                    <label key={index} htmlFor={`frequency-${index}`} className="custom-radio">
                                                        <input
                                                            type="radio"
                                                            id={`frequency-${index}`}
                                                            name="frequency"
                                                            value={option}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="c-label-r">{option}</span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="button-block">
                            <button
                                className="join submit-event"
                                type="submit"

                            >Submit Your Event</button>

                            <ConfirmationModal trigger={confirmationModal} setTrigger={setConfirmationModal} />
                        </div>

                    </div>
                </form>
            </div>
            <Footer />
        </>
    );
}

export default CreateEvent;
