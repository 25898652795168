import axios from 'axios';
import DateRange from './DateRange';

// const backend = require('./backend_api');
// import {getAllEvents} from './backend_api'
const secrets = require('./secrets');
const api = secrets.server_url;

//console.log(process.env.REACT_APP_S3_ACCES_KEY_ID);
// console.log(process.env.REACT_APP_S3_ACCES_KEY_ID);

//backend.getAllEvents();
//
async function getAllEvents() {

  try {
    const axiosResponse = await axios.request({
      method: "GET",
      url: secrets.server_url + "events/",
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }

    })
      .then((res) => res.data)
    // //console.log(axiosResponse);
    // // console.log(axiosResponse);
    return axiosResponse
  } catch {
    //console.error(error)
    throw new Error('Failed to fetch all events');
  }
}

const info = await getAllEvents();

const isUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};


info.forEach((info) => {
  // const imgURL = isUrl(info.img) ? info.img : `${secrets.server_url+"uploads/"}${info.img.split('/').pop()}`;
  // info.img = imgURL
  // // //console.log(imgURL)
  // // // console.log(imgURL)

  // // console.log(info.start_datetime)
  // // console.log(info.end_datetime)
  //hotfix for invalid time value from scraping

  if( !info.tags ){
    info.tags = []
  }

  function findFirstFutureDate(datesArray) {
    // console.log(datesArray)
    if( !datesArray ){
      info.dates = []
      return -1
    }

    if(datesArray.length === 0){
      // console.log("blleeeeee")
      return -1
    }

    const currentDate = new Date().setHours(0, 0, 0, 0); // Get the current date without time (midnight)
    console.log(currentDate)
    console.log(1)
    for (let i = 0; i < datesArray.length; i++) {
      const arrayDate = new Date(datesArray[i]).setHours(0, 0, 0, 0); // Parse the date and ignore the time
      console.log(arrayDate)
      if (arrayDate >= currentDate) {
        console.log(i)
        return i; // Return the index of the first date that has not passed or is today
      }
    }

    return info.dates.length - 1; // All dates have passed
  }
  console.log(1)


  
  const dateIndex = findFirstFutureDate(info.dates)
  info.dateIndex = dateIndex
  console.log(2)


  const lastDate = info.end_datetime ? info.end_datetime : info.dates[ info.dates.length - 1 ]
  info.lastDate = lastDate 
  console.log(3)


  if (info.start_datetime) {
    info.start_datetime = info.start_datetime.replace(/T(\d{1}):/, (match, p1) => `T0${p1}:`);
  }
  console.log(4)


  if (info.lastDate) {
    info.lastDate = info.lastDate.replace(/T(\d{1}):/, (match, p1) => `T0${p1}:`);
  }
  console.log(5)



  if (info.lastDate === 'N/A')
    info.lastDate = info.start_datetime
});
console.log(6)


// information.splice(0,2);

// //console.log(info);
// // console.log(info);

// const organizations = info.map(event => event.organization);

const startTimes = info.map(item => new Date(item.start_datetime));
console.log(7)


// // console.log("-----------------")

const startFormattedDates = startTimes.map(date => {

  const options = {
    weekday: 'long', // Saturday
    month: 'short',    // July
    day: 'numeric',   // 13
    // timeZone: 'America/Chicago' // Central Time Zone
  };


  // info.forEach((info) => {
  //     const imgURL = isUrl(info.img) ? info.img : `${secrets.server_url+"uploads/"}${info.img.split('/').pop()}`;
  //     info.img = imgURL

      // // console.log(info.start_datetime)
      // // console.log(info.end_datetime)
      //hotfix for invalid time value from scraping

      if(info.start_datetime){
        info.start_datetime = info.start_datetime.replace(/T(\d{1}):/, (match, p1) => `T0${p1}:`);
      }

      if(info.end_datetime){
        info.end_datetime = info.end_datetime.replace(/T(\d{1}):/, (match, p1) => `T0${p1}:`);
      }


      if(info.end_datetime === 'N/A')
        info.end_datetime = info.start_datetime
  


  // information.splice(0,2);

  // //console.log(info);
  // // console.log(info);

  // const organizations = info.map(event => event.organization);

  const timeOptions = {
    hour: 'numeric',  // 7
    minute: 'numeric', // 00
    hour12: true, // PM
    // timeZone: 'America/Chicago' // Central Time Zone
  };

  // //console.log(date)

  // // console.log(date)

  // console.log(date)

  let formattedDate = ""
  let formattedTime = ""

  try {
    formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  } catch {
    formattedDate = "Mon, Jan 1"
  }

  try {
    formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
  } catch {
    formattedTime = "12:00 AM"
  }


  // Tue, Apr 23
  // 6:30 PM

  // Custom formatting to remove ":00" if present
  const timeWithoutMinutes = formattedTime.replace(':00', ''); // Remove ":00" from the time string
  return `${formattedDate}  •  ${timeWithoutMinutes.toLowerCase()}`;
});
console.log(8)


// //console.log(startFormattedDates);



// //console.log("start time", startFormattedDates);
// // console.log(startFormattedDates);



// // console.log("start time", startFormattedDates);




// const endTimes = info.map(item => new Date(item.end_datetime));

// const endFormattedDates = endTimes.map(date => {
//   const options = {
//     weekday: 'long',
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//     hour: 'numeric',
//     minute: 'numeric',
//     second: 'numeric',
//     timeZoneName: 'short'
//   };
//   return date.toLocaleDateString('en-US', options);
// });

const endTimes = info.map(item => new Date(item.end_datetime));
console.log(9)

const endFormattedDates = endTimes.map(date => {
  const options = {
    weekday: 'long', // Sat
    month: 'long',    // July
    day: 'numeric',   // 13
  };

  const timeOptions = {
    hour: 'numeric',  // 7
    minute: 'numeric', // 00
    hour12: true, // PM
  };

  // //console.log(date)
  // // console.log(date)

  let formattedDate = ""
  let formattedTime = ""

  try {
    formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  } catch {
    formattedDate = "Monday , Jan 1st"
  }

  try {
    formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
  } catch {
    formattedTime = "12:00 AM"
  }


  // Custom formatting to remove ":00" if present
  const timeWithoutMinutes = formattedTime.replace(':00', ''); // Remove ":00" from the time string
  return `${formattedDate} | ${timeWithoutMinutes.toLowerCase()}`;
});
console.log(10)


// //console.log(endFormattedDates);


// //console.log("end time", endFormattedDates);
// // console.log(endFormattedDates);


// // console.log("end time", endFormattedDates);


const timeArray = startFormattedDates.map((item, index) => [item, endFormattedDates[index]]);
console.log(11)


// //console.log("date times", timeArray);
// // console.log("date times", timeArray);

const informationWithPastEvents = info.map((event, index) => {
  return { ...event, timeArray: timeArray[index] };
});

informationWithPastEvents.sort((a, b) => {
  // Get initial date strings
  const dateStrA = a.dateIndex >= 0 && a.dates ? 
    a.dates[a.dateIndex] : 
    a.start_datetime;

  const dateStrB = b.dateIndex >= 0 && b.dates ? 
    b.dates[b.dateIndex] : 
    b.start_datetime;

  // Handle 'N/A' or invalid dates
  if (dateStrA === 'N/A') return 1;  // Put N/A dates at the end
  if (dateStrB === 'N/A') return -1;

  // Parse dates using UTC to avoid timezone issues
  const dateA = new Date(dateStrA);
  const dateB = new Date(dateStrB);

  // Set time to midnight UTC for consistent comparison
  dateA.setUTCHours(0, 0, 0, 0);
  dateB.setUTCHours(0, 0, 0, 0);

  return dateA.getTime() - dateB.getTime();
});

//mark past events
const currentDateTime = new Date();

await informationWithPastEvents.forEach(item => {
  if(item.name === "Salatul Janazah and burial"){
  console.log(item.end_datetime)
  console.log(item.lastDate)
  }

  const endDateTime = new Date(item.lastDate); //item.lastDate

  if (endDateTime < currentDateTime) {
    item.past = true;
  }
  else {

    item.past = false
  }

  //change s3 urls to CloudFront urls
  if (item.img) {
    item.img = item.img.replace("https://ummahfy-event-images.s3.us-east-2.amazonaws.com", "https://img.ummahfy.com");
  }

});

const unapprovedEvents = await informationWithPastEvents.filter(item => !item.isApproved)

console.log(12)



// ip geolocation service
// async function getUserLocation() {

//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(
//         async (position) => {
//             // Success callback
//             const latitude = position.coords.latitude;
//             const longitude = position.coords.longitude;
//             console.log('User consented to share location:', latitude, longitude);

//             // Send the location data to your backend
//             // sendLocationToServer(latitude, longitude);
//             await processLocations(informationWithPastEvents, latitude, longitude);

//             const cityResponse = await axios.get(`${api}getCityFromCoords`, {
//               lat: latitude,
//               lon: longitude
//             }, {
//               headers: {
//                   'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
//               }
//             });

//             console.log(cityResponse.data)

//             return {
//               city: cityResponse.data,
//               lat: latitude,
//               lon: longitude,
//             }
//         },
//         (error) => {
//             // Error callback
//             console.error('User denied location access:', error.message);
//             // Handle the error accordingly, maybe log it or inform the user
//         }
//     );
// } else {
//     console.error('Geolocation is not supported by this browser.');
// }



//   // const apiKey = secrets.ipgeolocation_api_key; // Replace with your ipgeolocation.io API key
//   // const res = await axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}`);
//   // if (res.status === 200) {
//   //   return {
//   //     city: res.data?.city,
//   //     lat: res.data?.latitude,
//   //     lon: res.data?.longitude,
//   //   }
//   // } else {
//   //   return {
//   //     city: "",
//   //     lat: "",
//   //     lon: "",
//   //   }
//   // }
// }
console.log("d")

async function getUserLocation() {
  if (navigator.geolocation) {
    console.log(3)
    return new Promise((resolve, reject) => {
      console.log(4)
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // Success callback
          console.log(5)
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log('User consented to share location:', latitude, longitude);

          // Send the location data to your backend or process it
          console.log(6)
          await processLocations(informationWithPastEvents, latitude, longitude);
          console.log(7)

          try {
            console.log(8)
            const cityResponse = await axios.get(`${api}getCityFromCoords`, {
              params: {
                lat: latitude,
                lon: longitude
              },
              headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
              }
            });
            console.log(9)

            console.log(cityResponse.data);

            resolve({
              city: cityResponse.data.data,
              lat: latitude,
              lon: longitude,
            });
          } catch (error) {
            console.error('Error fetching city:', error);
            reject(error);
          }
        },
        (error) => {
          // Error callback
          console.error('User denied location access:', error.message);
          reject(error);
        }
      );
    });
  } else {
    console.error('Geolocation is not supported by this browser.');
    return Promise.reject('Geolocation is not supported by this browser.');
  }
}

console.log("e")



function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusMiles = 3963.0;

  // //console.log(lat1)
  // //console.log(lon1)
  // //console.log(lat2)
  // //console.log(lon2)
  // //console.log("--------")
  // // console.log(lat1)
  // // console.log(lon1)
  // // console.log(lat2)
  // // console.log(lon2)
  // // console.log("--------")

  const lat1Rad = lat1 * (Math.PI / 180);
  const lat2Rad = lat2 * (Math.PI / 180);
  const lon1Rad = lon1 * (Math.PI / 180);
  const lon2Rad = lon2 * (Math.PI / 180);

  const distance =
    earthRadiusMiles *
    Math.acos(
      Math.sin(lat1Rad) * Math.sin(lat2Rad) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lon2Rad - lon1Rad)
    );

  return distance;
}
console.log("a")


//const url = `${secrets.server_url}getEventLoc?placeName=${encodeURIComponent(placeName)}`;

// let userLoc = {
//   city: "Chicago",
//   lat: 41.8781,
//   lon: 87.6298,
// }

// userLoc = await getUserLocation();

let userLoc;

try {
  console.log(2)
  userLoc = await getUserLocation();
  console.log(1)
  console.log(userLoc); // Now this will correctly log the user's location
} catch (error) {
  console.error('Failed to get user location:', error);
}

console.log(userLoc)

// //console.log(userLoc)
// // console.log(userLoc)
console.log("b")


async function getEventLoc(placeName) {
  //console.log("enter geteventloc")
  const url = `${secrets.server_url}getEventLoc?placeName=${encodeURIComponent(placeName)}`;
  //console.log(url)
  try {
    console.log("kljsndln")
    const response = await axios.get(url, {
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      }
    });
    //console.log(response)
    const data = response.data;
    console.log(data)
    if (data.status === 'OK' && data.candidates.length > 0) {
      const location = data.candidates[0].geometry.location;


      return {
        latitude: location.lat,
        longitude: location.lng
      };
    } else {
      // throw new Error('Location not found');
      return null;
    }
  } catch (error) {
    // //console.log(placeName)
    // // console.log(placeName)
    // console.error('Error fetching location:', error);
    return null;
  }
}
console.log(13)


// Example usage:
// getEventLoc('501 E Roosevelt Rd, Lombard, IL 60148').then(location => {
// if (location) {
//   //console.log(`Latitude: ${location.latitude}, Longitude: ${location.longitude}`);
//   // console.log(`Latitude: ${location.latitude}, Longitude: ${location.longitude}`);
// }
// });

async function populateInformationWithCoords() {
  for (const item of informationWithPastEvents) {
    if (!item.location) {
      item.latitude = null
      item.longitude = null
      continue;
    }
    try {
      const eventLoc = await getEventLoc(item.location);
      // //console.log(eventLoc)
      // // console.log(eventLoc)
      if (eventLoc.latitude & eventLoc.longitude) {

        item.latitude = eventLoc.latitude
        item.longitude = eventLoc.longitude
      }
      else {
        item.latitude = null
        item.longitude = null
      }
    } catch (error) {
      item.latitude = null
      item.longitude = null
    }
  }
}

// await populateInformationWithCoords()


async function processLocations(informationArray, userLatitude, userLongitude) {

  for (const item of informationArray) {
    if (!(item.latitude & item.longitude)) {
      // console.log(item.latitude)
      // console.log(item.longitude)
      item.distanceFromUser = null
      continue;
    }
    try {

      if (userLatitude & userLongitude) {
        // // console.log(item.distanceFromUser)

        item.distanceFromUser = await calculateDistance(userLatitude, userLongitude, item.latitude, item.longitude)
        // // console.log(item.distanceFromUser)

      }
      else {
        // // console.log("a")
        item.distanceFromUser = null
      }
    } catch (error) {
      // // console.log("sum didnt work")
      item.distanceFromUser = null
    }

  }
  // //console.log(informationArray)
}
//test

try {
  // console.log(1)
  await processLocations(informationWithPastEvents, userLoc.lat, userLoc.lon);
}
catch {
  // console.log(2)
  await processLocations(informationWithPastEvents, 41.8781, 87.6298);
  // console.log(3)
}


// const information = (information) => {
//     return information.filter(item => !item.past);
// };

const filterInformation = (information) => {
  // return information.filter(item => !item.past);

  return information.filter(item => !item.past).filter(item => item.isApproved).filter(item => !item.isPrivate);
};

const information = filterInformation(informationWithPastEvents);
console.log(14)


//fetch session data
const fetchSessionData = async () => {
  const response = await axios.get(`${api}getLoggedInUser/`, {
    withCredentials: true, // Include cookies with requests
    headers: {
      'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    }

  });
  return response.data;
};

const fetchSessionEmail = async () => {
  const response = await axios.get(`${api}getLoggedInEmail/`, {
    withCredentials: true, // Include cookies with requests
    headers: {
      'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    }
  });
  return response.data;
};

const getUserDetails = async () => {

  if (loggedInUser && loggedInUser.data === "Enterprise User") {
    const response = await axios.get(`${api}enterpriseUsers/`, {
      withCredentials: true, // Include cookies with requests
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      },
      params: {
        email: loggedInEmail.data
      }
    });

    if (response && response.data)
      return response.data.data[0]
    else
      return null
  }

  if (!loggedInUser.data) {
    //console.log("akjdnka")
    return false
  }

  const response = await axios.get(`${api}users/${loggedInEmail.data}`, {
    withCredentials: true, // Include cookies with requests
    headers: {
      'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    }
  });

  //console.log(response.data)
  if (response && response.data)
    return response.data.data[0]
  else
    return null
}



const loggedInUser = await fetchSessionData();
const loggedInEmail = await fetchSessionEmail()
const userInfo = await getUserDetails()
let isAdmin = null
if (userInfo && loggedInUser.data !== "Enterprise User")
  isAdmin = userInfo.isAdmin

if(loggedInEmail){
  window.heap.identify(loggedInEmail);

}

// //console.log("with time", information);

console.log("session info:")
console.log(loggedInUser.data)
console.log(loggedInEmail.data)
console.log(userInfo)


let selectedOrganization = ""
let managedOrganizations = []
if (loggedInUser && loggedInUser.data === "Enterprise User") {
  const response = await axios.get(`${api}getSelectedOrganization/`, {
    withCredentials: true, // Include cookies with requests
    headers: {
      'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    }

  });
  console.log(response)
  if (response && response.data && response.data.data) {
    selectedOrganization = response.data.data
  }
  else {
    selectedOrganization = userInfo.organizations[0]
  }

  // get info for all orgs user manages
  userInfo.organizations.forEach(async function(org){
    const resp = await axios.get(`${api}organizations/`, {
      withCredentials: true, // Include cookies with requests
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      },
      params: {
        name: org
      }
    });

    if(resp && resp.data && resp.data.data && resp.data.data[0]){
      managedOrganizations.push( resp.data.data[0] )
    }
  });
}

let selectedOrganizationInfo = null
console.log(selectedOrganization)
if (selectedOrganization) {
  try {
    const response = await axios.get(`${api}organizations/`, {
      headers: {
        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
      },
      params: {
        name: selectedOrganization
      }
    });
    //console.log("User details:", response.data.data[0].email);
    selectedOrganizationInfo = response.data.data[0]
  } catch (error) {
    console.error(error);
    throw error; // Handle or rethrow error
  }
}
console.log(15)


async function getRegisteredUserEmails() {
  try {
      const response = await axios.get(`${api}users/`, {
          headers: {
              'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
          }
      });
      //console.log("User details:", response.data.data[0].email);
      console.log(response.data.data)
      return response.data.data.map(obj => obj.email);
  } catch (error) {
      console.error(error);
      throw error; // Handle or rethrow error
  }
}

const emails = await getRegisteredUserEmails();

if (loggedInEmail)
  console.log("loggedinemail: " + loggedInEmail.data)

console.log(99)



export { information, informationWithPastEvents, processLocations, getEventLoc, loggedInUser, loggedInEmail, isAdmin, userInfo, userLoc, unapprovedEvents, selectedOrganization, selectedOrganizationInfo, managedOrganizations, emails };
