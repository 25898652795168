import { loggedInUser, loggedInEmail, isAdmin } from "./information.js";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Menu, Dropdown } from 'antd';
import { UserOutlined, SearchOutlined, PlusOutlined, LogoutOutlined, LoginOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import Signin from './Signin';
import SignUp from './SignUp';
import axios from 'axios';
import NavbarLogo from './NavbarLogo.svg';
import OrganizerSignIn from "./OrganizerSignIn.js";

const secrets = require('./secrets.js');
const api = secrets.server_url;

const logout = async () => {
    try {
        const response = await axios.get(`${api}logout`, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
        });

        if (response.status === 200 && response.data.data) {
            window.location.href = '/';
        } else {
            console.error('Logout failed:', response.data.message);
            alert('Failed to logout');
        }
    } catch (error) {
        console.error('Error during logout:', error);
        alert('An error occurred during logout');
    }
};

const Navbar = () => {
    const [buttonSignin, setButtonSignin] = useState(false);
    const [buttonSignUp, setButtonSignUp] = useState(false);
    const [organizerSignin, setOrganizerSignin] = useState(false);
    const [showMenu, setShowMenu] = useState(window.innerWidth <= 890 ? false : true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [user, setUser] = useState(loggedInUser);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 890) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getMobileMenuItems = () => {
        if (user && user.data) {
            return [
                isAdmin && {
                    key: 'admin',
                    icon: <SettingOutlined />,
                    label: <Link to="/adminPanel" onClick={closeDrawer}>Admin</Link>,
                },
                (loggedInUser && loggedInUser.data === "Enterprise User") && {
                    key: 'organizer',
                    icon: <AppstoreOutlined />,
                    label: <Link to="/organizerHome" target="_blank" rel="noopener noreferrer" onClick={closeDrawer}>Organizer Page</Link>,
                },
                {
                    key: 'events',
                    icon: <SearchOutlined />,
                    label: <Link to="/events" onClick={closeDrawer}>Find Events</Link>,
                },
                {
                    key: 'create-event',
                    icon: <PlusOutlined />,
                    label: <Link to="/newCreateEvent" onClick={closeDrawer}>Create Event</Link>,
                },
                // {
                //     key: 'new-create-event',
                //     icon: <PlusOutlined />,
                //     label: <Link to="/newCreateEvent" onClick={closeDrawer}>NEW CE</Link>,
                // },
                {
                    key: 'profile',
                    icon: <UserOutlined />,
                    label: <Link to="/profile" onClick={closeDrawer}>Profile</Link>,
                },
                {
                    type: 'divider' // Add this line to create a divider
                },
                {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    danger: true,
                    label: 'Log Out',
                    onClick: () => { closeDrawer(); logout(); },
                },
            ].filter(Boolean);
        } else {
            return [
                {
                    key: 'events',
                    icon: <SearchOutlined />,
                    label: <Link to="/events" onClick={closeDrawer}>Find Events</Link>,
                },
                {
                    key: 'signin',
                    icon: <LoginOutlined />,
                    label: 'Sign In',
                    onClick: () => { setButtonSignin(true); closeDrawer(); },
                },
                {
                    key: 'organizer-signin',
                    icon: <LoginOutlined />,
                    label: 'Organizer Sign In',
                    onClick: () => { setOrganizerSignin(true); closeDrawer(); },
                },
            ];
        }
    };

    return (
        <div>
            <div className="navbar">
                <div className="item1">
                    <Link to="/" style={{ padding: '0', margin: '0', cursor: 'pointer' }}>
                        <img src={NavbarLogo} alt="navbar-logo" style={{ height: '30px', marginTop: '8px', cursor: 'pointer' }} />
                    </Link>
                </div>

                {showMenu ? (
                    <div className="item3">
                        <ul>
                            {isAdmin && (
                                <li>
                                    <Link to="/adminPanel">
                                        <button className="navb find-events-nav" onClick={closeDrawer}>Admin</button>
                                    </Link>
                                </li>
                            )}
                            {loggedInUser && loggedInUser.data === "Enterprise User" && (
                                <li>
                                    <Link to="/organizerHome" target="_blank" rel="noopener noreferrer">
                                        <button className="navb find-events-nav">Organizer Page</button>
                                    </Link>
                                </li>
                            )}

                            <li>
                                <Link to="/events">
                                    <button className="navb find-events-nav">Find Events</button>
                                </Link>
                            </li>
                            {user && user.data ? (
                                <>
                                    {/* <li>
                                        <Link to="/createEvent">
                                            <button className="navb create-event-nav">Create Event</button>
                                        </Link>
                                    </li> */}

                                    <li>
                                        <Link to="/newCreateEvent">
                                            <button className="navb create-event-nav">Create Event</button>
                                        </Link>
                                    </li>

                                    <li>
                                        {/* {loggedInUser.data === "Enterprise User" ? (
                                            <Link to="/organizerHome" target="_blank" rel="noopener noreferrer">
                                                <button className="navb find-events-nav">Profile</button>
                                            </Link>
                                        ) : (
                                            <Link to="/profile">
                                                <button className="navb create-event-nav">Profile</button>
                                            </Link>
                                        )} */}
                                        <Link to="/profile">
                                            <button className="navb create-event-nav">Profile</button>
                                        </Link>

                                    </li>
                                    <li>
                                        <button className="navb log-out" onClick={logout}>Log Out</button>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <button className="navb login" onClick={() => setButtonSignin(true)}>Sign In</button>
                                        <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
                                    </li>

                                    <li>
                                        <button className="navb org-login" onClick={() => setOrganizerSignin(true)}>Organizer Sign In</button>
                                        <OrganizerSignIn trigger={organizerSignin} setTrigger={setOrganizerSignin} />
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                ) : (
                    <div className="nav-icon-menu">
                        <OrganizerSignIn trigger={organizerSignin} setTrigger={setOrganizerSignin} />
                        <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />

                        <input type="checkbox" id="drawer-toggle" className="drawer-toggle" checked={isDrawerOpen} onChange={toggleDrawer} />
                        <label htmlFor="drawer-toggle" className="drawer-button">☰</label>
                        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
                            <nav>
                                <Menu
                                    mode="inline"
                                    items={getMobileMenuItems()}
                                    className="mobile-menu-custom"
                                    style={{
                                        border: 'none',
                                        width: 'auto',
                                        background: 'transparent',
                                        fontSize: '16px',
                                    }}
                                />
                            </nav>
                        </div>
                    </div>
                )}
            </div>

            <style jsx>{`
                .drawer-toggle {
                    display: none;
                }


                .mobile-menu-custom .ant-menu-item {
                    height: 48px;
                    line-height: 48px;
                    margin: 4px 0;
                }

                .mobile-menu-custom .ant-menu-submenu-title {
                    height: 48px;
                    line-height: 48px;
                }

                .mobile-menu-custom .ant-menu-item a {
                    color: rgba(0, 0, 0, 0.85);
                    text-decoration: none;
                }

                .mobile-menu-custom .ant-menu-item:hover {
                    background-color: #f5f5f5;
                }

                .mobile-menu-custom .ant-menu-item-icon {
                    font-size: 18px;
                }

                .drawer.open {
                    transform: translateX(0);
                }
            `}</style>
        </div>
    );
};

export default Navbar;