import { loggedInUser, loggedInEmail, isAdmin, selectedOrganization, selectedOrganizationInfo, userInfo, managedOrganizations } from "./information.js";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Signin from './Signin';
import SignUp from './SignUp';
import axios from 'axios';
import NavbarLogo from './NavbarLogo.svg';
import { Button, Menu, Dropdown } from 'antd';
import { SearchOutlined, UserOutlined, PlusOutlined, SettingOutlined, LogoutOutlined, LoginOutlined, AppstoreOutlined } from '@ant-design/icons';
import { ReactComponent as Avatar } from "./IconUmmahfy.svg";
import { ReactComponent as Avatar2 } from "./IconUmmahfyTwo.svg";
import UmmahfyOrg from './UmmahfyOrg.svg';

const secrets = require('./secrets.js');
const api = secrets.server_url;

const logout = async () => {
    try {
        const response = await axios.get(`${api}logout`, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
        });

        if (response.status === 200 && response.data.data) {
            window.location.href = '/';
        } else {
            console.error('Logout failed:', response.data.message);
            alert('Failed to logout');
        }
    } catch (error) {
        console.error('Error during logout:', error);
        alert('An error occurred during logout');
    }
};

const handleSwitchOrg = async (orgName) => {
    const response = await axios.post(`${api}switchSelectedOrganization/`, {
        org: orgName
    }, {
        withCredentials: true,
        headers: {
            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
    });
    window.location.reload()
};

const OrganizerNavbar = () => {
    const [buttonSignin, setButtonSignin] = useState(false);
    const [buttonSignUp, setButtonSignUp] = useState(false);
    const [showMenu, setShowMenu] = useState(window.innerWidth <= 890 ? false : true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [user, setUser] = useState(loggedInUser);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 890) {
                setShowMenu(false);
            } else {
                setShowMenu(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    const reorderedOrganizations = [
        selectedOrganization,
        ...userInfo.organizations.filter(org => org !== selectedOrganization),
    ];

    const getDesktopMenuItems = () => {
        return {
            items: [
                {
                    key: 'org-group',
                    type: 'group',
                    label: <div style={{ 
                        fontWeight: 600,  // or 'bold'
                        color: '#000000', // any color you want
                        fontSize: '16px',  // any size you want
                        padding: '0' // adjust padding as needed
                    }}>My Organizations</div>,
                    children: [
                        ...reorderedOrganizations.map((org, index) => {
                            const orgInfo = (managedOrganizations.filter(orga => orga.name === org))[0];
                            return {
                                key: `desktop-org-${index}`,
                                label: (
                                    <div
                                        className="org-account alt-org-acc"
                                        onClick={() => handleSwitchOrg(org)}
                                    >
                                        {orgInfo.profilePicture ? (
                                            <img className="org-prof-photo3" src={orgInfo.profilePicture} alt={org} />
                                        ) : (
                                            <Avatar className="org-prof-photo3" />
                                        )}
                                        <span>{org}</span>
                                    </div>
                                )
                            };
                        })
                    ]
                },
                {
                    type: 'divider'
                },
                {
                    key: 'logout',
                    label: <div onClick={logout}>Log Out</div>,
                    danger: true
                }
            ]
        };
    };

    const getMobileMenuItems = () => {
        if (user && user.data) {
            return [
                {
                    key: 'org-submenu',
                    icon: <AppstoreOutlined />,
                    label: 'My Organizations',
                    children: reorderedOrganizations.map((org, index) => {
                        const orgInfo = (managedOrganizations.filter(orga => orga.name === org))[0];
                        return {
                            key: `mobile-org-${index}`,
                            label: (
                                <div
                                    className="org-menu-item"
                                    onClick={() => handleSwitchOrg(org)}
                                >
                                    {orgInfo.profilePicture ? (
                                        <img
                                            className="org-prof-photo3"
                                            src={orgInfo.profilePicture}
                                            alt={org}
                                        />
                                    ) : (
                                        <Avatar className="org-prof-photo3" />
                                    )}
                                    <span className="mob-nav-org-name">{org}</span>
                                </div>
                            )
                        };
                    })
                },
                {
                    key: 'profile',
                    icon: <UserOutlined />,
                    label: <Link to="/organizerHome" onClick={closeDrawer}>Profile</Link>,
                },
                {
                    key: 'create-event',
                    icon: <PlusOutlined />,
                    label: <Link to="/newCreateEvent" onClick={closeDrawer}>Create Event</Link>,
                },
                {
                    key: 'events',
                    icon: <SearchOutlined />,
                    label: <Link to="/events" onClick={closeDrawer}>Find Events</Link>,
                },
                {
                    key: 'settings',
                    icon: <SettingOutlined />,
                    label: <Link to="/organizerSettings" onClick={closeDrawer}>Settings</Link>,
                },
                {
                    type: 'divider' // Add this line to create a divider
                },
                {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    danger: true,
                    label: 'Log Out',
                    onClick: () => { closeDrawer(); logout(); },
                },
            ];
        } else {
            return [
                {
                    key: 'login',
                    icon: <LoginOutlined />,
                    label: 'Log In',
                    onClick: () => { setButtonSignin(true); closeDrawer(); },
                },
                {
                    key: 'organizer-signin',
                    icon: <LoginOutlined />,
                    label: 'Organizer Signin',
                    onClick: () => { setButtonSignUp(true); closeDrawer(); },
                },
            ];
        }
    };

    return (
        <div>
            <div className="navbar">
                <div className="item1">
                    <Link to="/organizerHome" style={{ padding: '0', margin: '0', cursor: 'pointer' }}>
                        <img src={UmmahfyOrg} alt="navbar-logo" style={{ height: '30px', marginTop: '8px', cursor: 'pointer' }} />
                    </Link>
                </div>

                {showMenu ? (
                    <div className="item3">
                        <ul>
                            <li>
                                <Link to="/organizerHome">
                                    <button className="navb find-events-nav">Profile</button>
                                </Link>
                            </li>

                            <li>
                                <Link to="/newCreateEvent">
                                    <button className="navb find-events-nav">Create Event</button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/events">
                                    <button className="navb find-events-nav">Find Events</button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/organizerSettings">
                                    <button className="navb find-events-nav">Settings</button>
                                </Link>
                            </li>

                            <li>
                                <Dropdown
                                    menu={getDesktopMenuItems()}
                                    placement="bottomRight"
                                    trigger={['click']}
                                    arrow={{ pointAtCenter: true }}
                                    className="org-prof-switcher"
                                >
                                    <button className="navb nav-prof-photo">
                                        {selectedOrganizationInfo.profilePicture ? (
                                            <img
                                                className="org-prof-photo"
                                                src={selectedOrganizationInfo.profilePicture}
                                                alt="Profile"
                                            />
                                        ) : (
                                            <Avatar className="org-prof-photo" />
                                        )}
                                    </button>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div className="nav-icon-menu">
                        <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
                        <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />

                        <input type="checkbox" id="drawer-toggle" className="drawer-toggle" checked={isDrawerOpen} onChange={toggleDrawer} />
                        <label htmlFor="drawer-toggle" className="drawer-button">☰</label>
                        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
                            <nav>
                                <Menu
                                    mode="inline"
                                    items={getMobileMenuItems()}
                                    className="mobile-menu-custom"
                                    style={{
                                        border: 'none',
                                        width: 'auto',
                                        background: 'transparent',
                                        fontSize: '16px',
                                    }}
                                />
                            </nav>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrganizerNavbar;


