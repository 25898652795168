import React, { useState } from "react";
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { useNavigate } from 'react-router-dom';
import { information } from './information';
import axios from "axios";

const secrets = require("./secrets.js");
const api = secrets.server_url;

function EditRSVP(props) {
    console.log(props.id)
    console.log(props.handleUnRegister)

    return (props.trigger) ? (
        <>
            <div className="registration-modal-wrapper">
                <div className="registration-modal">
                    <div className="title-icon">
                        <p className="rm-title"> 
                            {props.isPaid ? "Paid Event Registration" : "Update Your RSVP"} 
                        </p>
                        
                        <CloseIcon className="close" onClick={() => props.setTrigger(false)} />
                    </div>

                    {props.isPaid ? (
                        <>
                            <p className="rm-subtext cancel-subtext">
                                This is a paid event. For refund requests or registration changes, 
                                please contact the event organizer directly.
                            </p>
                            
                            {props.info?.contact_email && (
                                <p className="rm-subtext">
                                    Contact: {props.info.contact_email}
                                </p>
                            )}

                            <button 
                                className="next-step-registration"
                                onClick={() => props.setTrigger(false)}
                            > 
                                Close 
                            </button>
                        </>
                    ) : (
                        <>
                            <p className="rm-subtext cancel-subtext">
                                Let us know if your plans have changed. Click the button below to update your status.
                            </p>

                            <button 
                                className="next-step-registration cancel-button" 
                                onClick={async () => {
                                    await props.handleUnRegister(props.id)
                                    window.location.reload()
                                }}
                            > 
                                Cancel Registration 
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    ) : "";
}

export default EditRSVP;