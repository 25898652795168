import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import axios from 'axios';
import { ReactComponent as GoogleLogo } from './GoogleLogo.svg';
import { loggedInEmail, emails } from "./information.js";
import ForgotPassword from "./ForgotPassword.js";
import { Modal, ConfigProvider } from 'antd';

import { Alert } from 'antd'; // Add this to your imports


const secrets = require('./secrets.js');
const api = secrets.server_url;

// async function handleSignin(email, password, setError) {
//     const users = (await axios.get(`${api}users/`, {
//         headers: {
//             'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
//         }
//     })).data.data;

//     const user = users.find(user => user.email === email);

//     if (!user) {
//         setError('No user found with this email');
//         return false;
//     }

//     const data = { password };
//     try {
//         const response = await axios.post(api + 'users/' + email, data, {
//             withCredentials: true,
//             headers: {
//                 'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
//             }
//         });

//         if (response.data.data === 'passwords match') {
//             window.location.reload();
//         } else {
//             console.log('Passwords do not match!');
//         }
//     } catch (error) {
//         console.error('Error:', error);
//         setError('An error occurred. Please try again.');
//     }
// }
// Modify the handleSignin function
async function handleSignin(email, password, setError) {
    const users = (await axios.get(`${api}users/`, {
        headers: {
            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
    })).data.data;

    const user = users.find(user => user.email === email);

    if (!user) {
        setError('No user found with this email');
        return false;
    }

    const data = { password };
    try {
        const response = await axios.post(api + 'users/' + email, data, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
        });

        if (response.data.data === 'passwords match') {
            window.location.reload();
        } else {
            setError('Incorrect password'); // Add this line to show password error
        }
    } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data.message === 'Invalid password') {
            setError('Incorrect password');
        } else {
            setError('An error occurred. Please try again.');
        }
    }
}

// Also add some CSS for the error message styling if you haven't already:


async function createUser(username, email, password, phone, birthday, email_notifs, text_notifs, gender, setError) {
    try {
        const response = await axios.post(`${api}users/`, null, {
            params: {
                username,
                email,
                password,
                phone,
                birthday,
                email_notifs,
                text_notifs,
                gender
            },
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            },
            withCredentials: true // Include cookies with requests
        });
        if (response.data.data === 'email exists') {
            setError('This email already exists.');
        } else {
            window.location.reload();
        }
        return JSON.stringify(response.data);
    } catch (error) {
        console.error(error);
        throw error; // Handle or rethrow error
    }
}

async function handleSignUp(username, newPassword, email, setError) {
    const response = await createUser(username, email, newPassword, "", "", false, false, "", setError)
        .then(async (response) => {
            if (loggedInEmail.data) {
                const payload = {
                    email: loggedInEmail.data,
                    type: "Sign Up"
                };

                try {
                    const response = await axios.post(`${api}sendEmail`, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                        }
                    });
                    //console.log('Response:', response.data);
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
            }
        })
    //console.log(response);
}


function Signin(props) {
    const [email, setEmail] = useState('');
    const [liPassword, setLiPassword] = useState('');
    const [buttonSignUp, setButtonSignUp] = useState(false);
    const [error, setError] = useState('');

    //need to toggle this to test this feature.
    const [isRegistered, setIsRegistered] = useState(true);


    const [fullName, setFullName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [emailError, setEmailError] = useState('');
    const fullNameInputRef = useRef(null); // Reference to the password input
    const [forgotPassword, setForgotPassword] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);




    useEffect(() => {
        if (props.trigger) {
            // Disable scrolling when the modal is open
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling when the modal is closed
            document.body.style.overflow = '';
        }

        // Cleanup function to ensure scrolling is enabled when component unmounts
        return () => {
            document.body.style.overflow = '';
        };
    }, [props.trigger]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isRegistered) {
            handleSignin(email, liPassword, setError);
        } else {
            // Check if passwords match
            if (newPassword !== confirmPassword) {
                setPasswordMatchError('Passwords do not match');
                return;
            }

            // Reset error messages
            setPasswordMatchError('');
            setEmailError('');

            // Handle sign up
            handleSignUp(fullName, newPassword, email, setEmailError);
        }

    };

    async function handleGoogleLogin() {
        window.location.href = api + "auth/google";
    }

    async function handleGoogleSignup() {
        window.location.href = api + "auth/google";
    }

    const handleClose = () => {
        setError(''); // Clear the error message when closing the component
        props.setTrigger(false);
        setIsRegistered(true);

    };






    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        // Check if the entered email is in the admitted list
        if (!email) {
            setIsRegistered(true);
        }
    };

    const handleEmailBlur = async () => {
        if (!emails.includes(email)) {
            setIsRegistered(false);
            // Focus on fullNameInputRef if it's not null
            if (fullNameInputRef.current) {
                fullNameInputRef.current.focus();
            }
        } else {
            setIsRegistered(true);
        }
    };

    const handleForgotPassword = async () => {
        console.log("calling handleforgotpass")
        try {
            const response = await axios.post(`${api}requestPasswordReset`, {
                email: email,
                accountType: "User",
                requestType: "Forgot Password"
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            })
            console.log(response)
        }
        catch (err) {
            console.error(err)
        }
    }

    // const handleEmailBlur = () => {
    //     // Perform the validation when the input loses focus
    //     if (emails.includes(email) || !email) {
    //         setIsRegistered(true);
    //     } else {
    //         setIsRegistered(false);
    //         // Check ref existence before calling focus
    //         if (fullNameInputRef.current) {
    //             fullNameInputRef.current.focus();
    //         }        }
    // };

    return (
        <>
            <ForgotPassword trigger={forgotPassword} setTrigger={setForgotPassword} />
            {props.trigger && !buttonSignUp && (
                <div>
                    <div className="signin-wrapper">
                        <div className="signin-page">
                            <div className="left-part">
                                <div className="sign-title">
                                    {!isRegistered ? <>
                                        <p>Sign Up to Ummahfy</p>
                                    </> : <>
                                        <p>Sign In or Sign Up</p>
                                    </>}


                                    <CloseIcon className="close" onClick={handleClose} />
                                </div>

                                <form onSubmit={handleSubmit}>

                                    {/* this is the starting point for deciding if this is a new user or not */}



                                    <div className="inputs">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            required
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="long-input"
                                            value={email}
                                            onChange={handleEmailChange}
                                            onBlur={handleEmailBlur}
                                        />
                                    </div>



                                    {isRegistered ? <>
                                        <div className="inputs">
                                            <label htmlFor="password">Password</label>
                                            <input
                                                required
                                                type="password"
                                                id="password"
                                                name="password"
                                                className="long-input"
                                                onChange={(e) => setLiPassword(e.target.value)}
                                            />
                                        </div>


                                        <a class="forgot-password"
                                            onClick={() => {
                                                setForgotPassword(true)
                                                props.setTrigger(false)
                                            }}

                                        > Forgot Password?
                                        </a>


                                        {/* {error && <div className="error-message">{error}</div>} */}
                                        {error && (
                                            <Alert
                                            
                                                message={error}
                                                type="error"
                                                showIcon
                                                style={{
                                                    maxHeight: '42px',
                                                    marginTop: '8px',
                                                    borderRadius: '4px',
                                                }}
                                                closable
                                                onClose={() => setError('')}
                                            />
                                        )}

                                        <button className="signin">Sign In</button>



                                        <div className="divider">OR</div>
                                        <button className="signin google" onClick={handleGoogleLogin}>
                                            <GoogleLogo className="google-logo" />
                                            Continue with Google
                                        </button>

                                    </>
                                        :
                                        <>
                                            <div className="inputs">
                                                <label htmlFor="fullName">Full Name </label>
                                                <input
                                                    type="text"
                                                    id="fullName"
                                                    name="fullName"
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                    className="long-input"
                                                    ref={fullNameInputRef}
                                                    required
                                                />
                                            </div>

                                            <div className="inputs">
                                                <label htmlFor="password">Password</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    className="long-input"
                                                    required
                                                />
                                            </div>

                                            <div className="inputs">
                                                <label htmlFor="confirm">Confirm Password</label>
                                                <input
                                                    type="password"
                                                    id="confirm"
                                                    name="confirm"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    className="long-input"
                                                    required
                                                />
                                            </div>

                                            {/* {passwordMatchError && <p className="error-message">{passwordMatchError}</p>}
                                            {emailError && <p className="error-message">{emailError}</p>} */}
                                            {passwordMatchError && (
                                                <Alert
                                                    message={passwordMatchError}
                                                    type="error"
                                                    showIcon
                                                    style={{
                                                        maxHeight: '42px',
                                                        marginTop: '8px',
                                                        borderRadius: '4px',
                                                    }}
                                                    closable
                                                    onClose={() => setPasswordMatchError('')}
                                                />
                                            )}
                                            {emailError && (
                                                <Alert
                                                    message={emailError}
                                                    type="error"
                                                    showIcon
                                                    style={{
                                                        maxHeight: '42px',
                                                        marginTop: '8px',
                                                        borderRadius: '4px',
                                                    }}
                                                    closable
                                                    onClose={() => setEmailError('')}
                                                />
                                            )}

                                            <button type="submit" className="create-acc">Create Account</button>

                                            <div className="divider">OR</div>

                                            <button className="signin google" onClick={handleGoogleSignup}>
                                                <GoogleLogo className="google-logo" />
                                                Continue with Google
                                            </button>

                                        </>}

                                </form>





                                {/* <div className="divider">OR</div>
                                <button className="signin google" onClick={handleGoogleLogin}>
                                    <GoogleLogo className="google-logo" />
                                    Continue with Google
                                </button> */}

                                {/* <button
                                    className="reg"
                                    onClick={() => {
                                        props.setTrigger(false);
                                        setButtonSignUp(true);
                                    }}
                                >
                                    Not Registered? <b>Create Your Account Here</b>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default Signin;


{/* 
                                    <div className="t-c">
                                        <label htmlFor="terms" className="custom-checkbox terms-cond">
                                            <input type="checkbox" id="terms" name="terms" required />
                                            <span className="checkmark"></span>
                                            <p className="terms">By checking this box, I acknowledge that I have read and agree to abide <br /> by the terms and conditions outlined in the Terms of Service.</p>
                                        </label>
                                    </div> */}