
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import axios from 'axios';
import {emails} from './information.js'


const secrets = require('./secrets.js');
const api = secrets.server_url;




function ForgotPassword(props) {

    const [email, setEmail] = useState('');
    const [isRegistered, setIsRegistered] = useState(true);
    const fullNameInputRef = useRef(null); // Reference to the password input
    


    const handleClose = () => {
        props.setTrigger(false);


    };

    const handleForgotPassword = async () => {
        console.log("calling handleforgotpass")

        try {
            const response = await axios.post(`${api}requestPasswordReset`, {
                email: email,
                accountType: "User",
                requestType: "Forgot Password"
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            })
            console.log(response)
        }
        catch (err) {
            console.error(err)
        }
        window.location.href = "/";
    }


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        // Check if the entered email is in the admitted list
        if (!email) {
            setIsRegistered(true);
        }
    };

    const handleEmailBlur = async () => {
        if (!emails.includes(email)) {
            setIsRegistered(false);
            // Focus on fullNameInputRef if it's not null
            if (fullNameInputRef.current) {
                fullNameInputRef.current.focus();
            }
        } else {
            setIsRegistered(true);
        }
    };

    return (
        <>
        {props.trigger && (
            <div>
                <div className="signin-wrapper">
                    <div className="signin-page">
                        <div className="left-part">
                            <div className="sign-title">
                                <p> Forgot Password </p>
                                <CloseIcon className="close" onClick={handleClose} />
                            </div>

                            <form >
                                <div className="inputs">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        required
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="long-input"
                                        value={email}
                                        onChange={handleEmailChange}
                                        onBlur={handleEmailBlur}
                                    />
                                </div>
                                
                                <button class="signin" onClick={handleForgotPassword}>  Reset Password </button>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
    );
}

export default ForgotPassword;