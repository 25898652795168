import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  const redirectToOrganizerHome = () => {
    window.location.href = '/organizerHome';
  };

  return { redirectToOrganizerHome };
};

export default useRedirect;