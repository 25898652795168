import React from "react";
import moment from 'moment';


function HomeCard(props) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const formatPrice = (price) => {
        // Check if price is 0 or "Free" and return "Free" directly
        if (price === "0" || price === "Free") {
            return "Free";
        }

        // Convert price to string if it's a number
        let priceStr = price.toString();

        // Add '$' if it's not already present
        if (!priceStr.startsWith("$")) {
            priceStr = `$${priceStr}`;
        }

        return priceStr;
    };

    const formatTime = (time) => {
        const momentTime = moment(time, 'HH:mm:ss.SSS');
        const minutes = momentTime.minutes();
        let hours = momentTime.hours();
        const ampm = hours >= 12 ? 'pm' : 'am';
     
        // Convert 24-hour to 12-hour format 
        hours = hours % 12;
        hours = hours ? hours : 12; // If hours is 0, make it 12
     
        // Pad minutes with leading zero
        const paddedMinutes = minutes.toString().padStart(2, '0');
     
        return minutes === 0
          ? `${hours}${ampm}` // shows "6pm" when no minutes
          : `${hours}:${paddedMinutes}${ampm}`; // shows "6:30pm" when has minutes
     };


    return (
        <div class="card-home">

            <div class="img-mask">
                <img src={props.imgurl} />
            </div>



            <div class="card-home-right">
                <div class="event">
                    {props.event}
                </div>

                <div class="masjid"> {props.masjid} </div>

                <div class="date">
                    <p>
                        {props.date === "Mon, Jan 1 • 12 am"
                            ? `${moment(props.altdate).format('ddd, MMM D')} · ${formatTime(props.time)}`
                            : props.date
                        }
                    </p>
                </div>

                <div className="badge-grp bdgrp-evsh">
                    {props.frequency && props.frequency !== 'Once' && (
                        <div className="badge time-badge">
                            <p>{capitalizeFirstLetter(props.frequency)}</p>
                        </div>
                    )}

                    {props.age && props.age !== 'all' && (
                        <div className="badge age-badge">
                            <p>{capitalizeFirstLetter(props.age)}</p>
                        </div>
                    )}

                    {props.gender && props.gender !== ('all' || '') && (
                        <div className="badge gender-badge">
                            <p>{capitalizeFirstLetter(props.gender)}</p>
                        </div>
                    )}

                    <div className="badge price-badge">
                        <p>{formatPrice(props.price)}</p>
                    </div>

                    {props.tags && props.tags.map((tag, index) => (
                        <div key={index} className="badge tag-badge">
                            <p>{tag}</p>
                        </div>
                    ))}
                </div>
            </div>





        </div>

    )

}

export default HomeCard;