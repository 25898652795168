// // FilterPanel.js
// import React, { useState, useEffect } from 'react';
// import { DatePicker } from 'antd';
// import LocationSearchInput from './LocationSearchInput';
// import { ReactComponent as ChevronRight } from './ChevronRight.svg';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { SearchOutlined } from '@ant-design/icons';
// import { userLoc, information } from './information';

// const FilterPanel = ({ setFilters }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [organizers, setOrganizers] = useState({
//     masjid: false,
//     community: false
//   });

//   const [ageGroups, setAgeGroups] = useState({
//     youth: false,
//     "highschool/college": false,
//     adult: false
//   });

//   const [gender, setGender] = useState({
//     male: false,
//     female: false
//   });

//   const [recurring, setRecurring] = useState({
//     daily: false,
//     weekly: false,
//     monthly: false
//   });

//   const [price, setPrice] = useState({
//     free: false,
//     paid: false
//   });

//   const determineDefaultDistance = () => {
//     const infoWithinRad = information.filter(item => item.distanceFromUser < 50);
//     if (infoWithinRad.length > 0){
//       return 50
//     } else {
//       return 0
//     }
//   };

//   const [selectedDate, setSelectedDate] = useState(null);
//   const [distance, setDistance] = useState(determineDefaultDistance);
//   const [search, setSearch] = useState("");
//   const [location, setLocation] = useState("");
//   const [reset, setReset] = useState(false);
//   const [isFilterEvents, setIsFilterEvents] = useState(false);

//   const handleDistanceChange = (event) => {
//     setDistance(parseInt(event.target.value, 10));
//   };

//   const onSearchChange = (event) => {
//     setSearch(event.target.value);
//   };

//   const handlePlaceSelected = (place) => {
//     setLocation(place.formatted_address);
//     setReset(false);
//   };

//   const resetPlaceSelected = () => {
//     setLocation('');
//     setDistance(0);
//     setReset(true);
//   };

//   useEffect(() => {
//     const filters = {
//       org_type: organizers,
//       age_range: ageGroups,
//       gender: gender,
//       frequency: recurring,
//       price: price,
//       date: selectedDate,
//       distance: distance,
//       search: search,
//       location: location
//     }
//     setFilters(filters);
//   }, [organizers, ageGroups, gender, recurring, price, selectedDate, distance, search, location]);

//   const handleOrganizersChange = (e) => {
//     setOrganizers({
//       ...organizers,
//       [e.target.name]: e.target.checked
//     });
//   };

//   const handleAgeGroupsChange = (e) => {
//     setAgeGroups({
//       ...ageGroups,
//       [e.target.name]: e.target.checked
//     });
//   };

//   const handleGenderChange = (e) => {
//     setGender({
//       ...gender,
//       [e.target.name]: e.target.checked
//     });
//   };

//   const handleRecurringChange = (e) => {
//     setRecurring({
//       ...recurring,
//       [e.target.name]: e.target.checked
//     });
//   };

//   const handlePriceChange = (e) => {
//     setPrice({
//       ...price,
//       [e.target.name]: e.target.checked
//     });
//   };

//   const onDateChange = (date, dateString) => {
//     setSelectedDate(dateString);
//   };

//   return (
//     <div className="filter-panel-container">
//       <div className={`filter-panel-slider ${isFilterEvents ? 'slide' : ''}`}>
//         {/* First Panel */}
//         <div className="filter-panel-section">
//           <div className="filter-panel">
//             <div className="search-filter">
//               <label className="fp-label" htmlFor="search">Search</label>
//               <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
//                 <input
//                   type="text"
//                   onChange={onSearchChange}
//                   className="content-search-input"
//                   placeholder="Search for organizers and events"
//                 />
//                 <SearchOutlined style={{ position: 'absolute', right: '10px', color: '#bfbfbf' }} />
//               </div>
//             </div>

//             <div className="date-n-time">
//               <label className="fp-label" htmlFor="dne">Date</label>
//               <DatePicker inputReadOnly className="date-picker" onChange={onDateChange}/>
//             </div>

//             <div className="search-filter location-filter">
//               <div className="label-reset">
//                 <label htmlFor="search">Location</label>
//                 <button onClick={resetPlaceSelected} className="reset-loc-button">
//                   Reset
//                 </button>
//               </div>
//               <LocationSearchInput currentLoc={userLoc} onPlaceSelected={handlePlaceSelected} reset={reset} />
//             </div>

//             <div className="distance-slider">
//               <label className="slider-label" htmlFor="vol">
//                 Distance: {(distance === 0) ? 'All' : `${distance} mi`}
//               </label>
//               <input
//                 className="slider"
//                 type="range"
//                 id="vol"
//                 name="vol"
//                 min="0"
//                 max="50"
//                 value={distance}
//                 onChange={handleDistanceChange}
//               />
//             </div>

//             <div>
//               <button className="filter-events-button" onClick={() => setIsFilterEvents(true)}>
//                 <div className="feb-text-icon">
//                   <p>Filter Events</p>
//                   <ChevronRight className="chevron-right" />
//                 </div>
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* Second Panel */}
//         <div className="filter-panel-section">
//           <div className="filter-panel">
//             <div className={`filter-parent-div ${isOpen ? "open" : ""}`}>
//               <button className="filter-back-btn" onClick={() => setIsFilterEvents(false)}>Back</button>
//               <div className="title-n-button">
//                 <p className="filter-title">Filter Events</p>
//               </div>
//               <div className="filter-content">
//                 <div className="group-box">
//                   <label htmlFor="masjid" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="masjid"
//                       name="masjid"
//                       checked={organizers.masjid}
//                       onChange={handleOrganizersChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Masjid</span>
//                   </label>
//                   <label htmlFor="community" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="community"
//                       name="community"
//                       checked={organizers.community}
//                       onChange={handleOrganizersChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Community</span>
//                   </label>
//                 </div>

//                 <div className="group-box">
//                   <label htmlFor="youth" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="youth"
//                       name="youth"
//                       checked={ageGroups.youth}
//                       onChange={handleAgeGroupsChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Youth</span>
//                   </label>
//                   <label htmlFor="hsCollege" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="hsCollege"
//                       name="hsCollege"
//                       checked={ageGroups.hsCollege}
//                       onChange={handleAgeGroupsChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">HS/College</span>
//                   </label>
//                   <label htmlFor="adult" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="adult"
//                       name="adult"
//                       checked={ageGroups.adult}
//                       onChange={handleAgeGroupsChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Adult</span>
//                   </label>
//                 </div>

//                 <div className="group-box">
//                   <label htmlFor="male" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="male"
//                       name="male"
//                       checked={gender.male}
//                       onChange={handleGenderChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Male</span>
//                   </label>
//                   <label htmlFor="female" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="female"
//                       name="female"
//                       checked={gender.female}
//                       onChange={handleGenderChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Female</span>
//                   </label>
//                 </div>

//                 <div className="group-box">
//                   <label htmlFor="weekly" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="weekly"
//                       name="weekly"
//                       checked={recurring.weekly}
//                       onChange={handleRecurringChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Weekly</span>
//                   </label>
//                   <label htmlFor="monthly" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="monthly"
//                       name="monthly"
//                       checked={recurring.monthly}
//                       onChange={handleRecurringChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Monthly</span>
//                   </label>
//                 </div>

//                 <div className="group-box recurring">
//                   <label htmlFor="free" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="free"
//                       name="free"
//                       checked={price.free}
//                       onChange={handlePriceChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Free</span>
//                   </label>
//                   <label htmlFor="paid" className="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id="paid"
//                       name="paid"
//                       checked={price.paid}
//                       onChange={handlePriceChange}
//                     />
//                     <span className="checkmark"></span>
//                     <span className="c-label">Paid</span>
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FilterPanel;








// FilterPanel.js

import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import LocationSearchInput from './LocationSearchInput';
import { ReactComponent as ChevronRight } from './ChevronRight.svg';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SearchOutlined } from '@ant-design/icons';
import { userLoc, information } from './information';



const FilterPanel = ({ setFilters }) => {
  const [isOpen, setIsOpen] = useState(false);

  // State variables for each filter category
  const [organizers, setOrganizers] = useState({
    masjid: false,
    community: false
  });

  const [ageGroups, setAgeGroups] = useState({
    youth: false,
    "highschool/college": false,
    adult: false
  });

  const [gender, setGender] = useState({
    male: false,
    female: false
  });

  const [recurring, setRecurring] = useState({
    daily: false,
    weekly: false,
    monthly: false
  });

  const [price, setPrice] = useState({
    free: false,
    paid: false
  });

  const determineDefaultDistance = () => {

    if(!userLoc){
      return 0
    }
    
    const infoWithinRad = information.filter(item => item.distanceFromUser && item.distanceFromUser < 50);

    if (infoWithinRad.length > 0){
      return 50
    } else {
      return 0
    }

  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [distance, setDistance] = useState(determineDefaultDistance);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [reset, setReset] = useState(false);

  // const handleDistanceChange = (event) => {
  //   setDistance(event.target.value);
  // };

  const handleDistanceChange = (event) => {
    setDistance(parseInt(event.target.value, 10)); // Convert the value to a number
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handlePlaceSelected = (place) => {
    // Handle the selected place here
    setLocation(place.formatted_address);
    setReset(false); // Reset state to false after a place is selected
  };

  const resetPlaceSelected = () => {
    // Handle the reset action here
    setLocation('');
    setDistance(0);
    setReset(true); // Set reset state to true to clear the input field
  };

  useEffect(() => {
    const filters = {
      org_type: organizers,
      age_range: ageGroups,
      gender: gender,
      frequency: recurring,
      price: price,
      date: selectedDate, // Pass the selected date to filters
      distance: distance,
      search: search,
      location: location
    }

    setFilters(filters);
  }, [organizers, ageGroups, gender, recurring, price, selectedDate, distance, search, location]);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  // Handler functions for each filter category
  const handleOrganizersChange = (e) => {
    setOrganizers({
      ...organizers,
      [e.target.name]: e.target.checked
    });
  };

  const handleAgeGroupsChange = (e) => {
    setAgeGroups({
      ...ageGroups,
      [e.target.name]: e.target.checked
    });
  };

  const handleGenderChange = (e) => {
    setGender({
      ...gender,
      [e.target.name]: e.target.checked
    });
  };

  const handleRecurringChange = (e) => {
    setRecurring({
      ...recurring,
      [e.target.name]: e.target.checked
    });
  };

  const handlePriceChange = (e) => {
    setPrice({
      ...price,
      [e.target.name]: e.target.checked
    });
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(dateString); // Set the selected date
    // //console.log(date, dateString);
  };

  const [isFilterEvents, setIsFilterEvents] = useState(false);

  return (
    <>

      {isFilterEvents ? (
        <>

          <div class="filter-panel">
            <div className={`filter-parent-div ${isOpen ? "open" : ""}`}>
              <button className="filter-back-btn" onClick={() => setIsFilterEvents(false)}> Back </button>
              <div className="title-n-button">
                <p className="filter-title">Filter Events</p>
              </div>
              <div className="filter-content">
                {/* <p className="groups">Organizers</p> */}

                <div className="group-box">
                  <label htmlFor="masjid" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="masjid"
                      name="masjid"
                      checked={organizers.masjid}
                      onChange={handleOrganizersChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Masjid</span>
                  </label>
                  <label htmlFor="community" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="community"
                      name="community"
                      checked={organizers.community}
                      onChange={handleOrganizersChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Community</span>
                  </label>
                </div>

                {/* <div class="div-ider"/> */}

                {/* <p className="groups">Age</p> */}
                <div className="group-box">
                  <label htmlFor="youth" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="youth"
                      name="youth"
                      checked={ageGroups.youth}
                      onChange={handleAgeGroupsChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Youth</span>
                  </label>
                  <label htmlFor="hsCollege" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="hsCollege"
                      name="hsCollege"
                      checked={ageGroups.hsCollege}
                      onChange={handleAgeGroupsChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">HS/College</span>
                  </label>
                  <label htmlFor="adult" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="adult"
                      name="adult"
                      checked={ageGroups.adult}
                      onChange={handleAgeGroupsChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Adult</span>
                  </label>
                </div>

                {/* <div class="div-ider"/> */}

                {/* <p className="groups">Gender</p> */}
                <div className="group-box">
                  <label htmlFor="male" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="male"
                      name="male"
                      checked={gender.male}
                      onChange={handleGenderChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Male</span>
                  </label>
                  <label htmlFor="female" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="female"
                      name="female"
                      checked={gender.female}
                      onChange={handleGenderChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Female</span>
                  </label>
                </div>

                {/* <div class="div-ider"/> */}

                {/* <p className="groups">Recurring</p> */}
                <div className="group-box">
                  <label htmlFor="weekly" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="weekly"
                      name="weekly"
                      checked={recurring.weekly}
                      onChange={handleRecurringChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Weekly</span>
                  </label>
                  <label htmlFor="monthly" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="monthly"
                      name="monthly"
                      checked={recurring.monthly}
                      onChange={handleRecurringChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Monthly</span>
                  </label>
                </div>

                {/* <div class="div-ider"/> */}

                {/* <p className="groups">Price</p> */}
                <div className="group-box recurring">
                  <label htmlFor="free" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="free"
                      name="free"
                      checked={price.free}
                      onChange={handlePriceChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Free</span>
                  </label>
                  <label htmlFor="paid" className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="paid"
                      name="paid"
                      checked={price.paid}
                      onChange={handlePriceChange}
                    />
                    <span className="checkmark"></span>
                    <span className="c-label">Paid</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="filter-panel">

            <div className="search-filter">
              <label class="fp-label" htmlFor="search"> Search </label>
              {/* <input placeholder="Search " type="text" id="search" name="search" className="dne-input" onChange={onSearchChange}/> */}
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  onChange={onSearchChange}
                  className="content-search-input"
                  placeholder="Search for organizers and events"
                />
                <SearchOutlined style={{ position: 'absolute', right: '10px', color: '#bfbfbf' }} />
              </div>
            </div>

            <div className="date-n-time">
              <label class="fp-label" htmlFor="dne"> Date  </label>
              <DatePicker inputReadOnly className="date-picker" onChange={onDateChange}/>
            </div>

            <div className="search-filter location-filter">
              <div className="label-reset">
                <label htmlFor="search"> Location </label>
                <button onClick={resetPlaceSelected} className="reset-loc-button">
                  Reset
                </button>
              </div>
              <LocationSearchInput currentLoc={userLoc} onPlaceSelected={handlePlaceSelected} reset={reset} />
            </div>

            <div>

            </div>

            <div className="distance-slider">
              <label className="slider-label" htmlFor="vol">
                Distance: {(distance === 0 ) ? 'All' : `${distance} mi`}
              </label>
              <input
                className="slider"
                type="range"
                id="vol"
                name="vol"
                min="0"
                max="50"
                value={distance}
                onChange={handleDistanceChange}
              />
            </div>


            <div >
              <button class="filter-events-button" onClick={() => setIsFilterEvents(true)}>
                <div class="feb-text-icon">
                  <p> Filter Events </p>
                  <ChevronRight className="chevron-right" />
                </div>

              </button>
            </div>
          </div>

        </>
      )}
    </>

  );
};

export default FilterPanel;
