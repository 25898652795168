import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home";
import Events from "./Events";
import AdminPanel from "./AdminPanel";
import EventShell from "./EventShell";
import CreateEvent from "./CreateEvent";
import Profile from "./Profile";
import OrganizerHome from "./OrganizerHome";
import OrganizerSettings from "./OrganizerSettings"
import Loading from "./Loading"; // Adjust the path as needed
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "./ErrorPage";
import PasscodeScreen from "./PasscodeScreen"; // Import PasscodeScreen component
import NewCreateEvent from "./NewCreateEvent";
import ResetPassword from "./ResetPassword";
import Ticket from "./Ticket";
import { information } from "./information";


const redirectToExternalBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/Instagram/i)) {
    if (/android/i.test(userAgent)) {
      window.location.href = "intent://www.ummahfy.com#Intent;scheme=https;package=com.android.chrome;end";
    }
  }
};

function App() {
  const info = information;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [minLoadDuration] = useState(900); // Minimum duration to show loading screen (1 second)
  const [maxLoadDuration] = useState(10000); // Maximum loading time (10 seconds)
  const [passcodeVerified, setPasscodeVerified] = useState(false); // Passcode state

  useEffect(() => {
    if (location.pathname === '/') {
      if (isInitialLoad) {
        // Start loading and record start time
        setIsLoading(true);
        setStartTime(Date.now());

        const initialLoadTimeout = setTimeout(() => {
          setIsLoading(false);
          setIsInitialLoad(false); // Update flag after initial load
        }, minLoadDuration);

        // Ensure cleanup on component unmount
        return () => clearTimeout(initialLoadTimeout);
      }
    } else {
      // Ensure loading is false for other routes
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoading && startTime) {
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, maxLoadDuration - elapsedTime);

      if (remainingTime > 0) {
        const extendLoadTimeout = setTimeout(() => {
          setIsLoading(false);
        }, remainingTime);

        return () => clearTimeout(extendLoadTimeout);
      }
    }
  }, [isLoading, startTime]);

  useEffect(() => {
    redirectToExternalBrowser();
  }, []);

  // Function to handle passcode verification
  const handlePasscodeVerification = (isValid) => {
    if (isValid) {
      setPasscodeVerified(true);
    } else {
      // Handle incorrect passcode
      alert("Invalid Passcode");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <>
            <ScrollToTop /> {/* Ensure this is rendered above your Routes */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/events" element={<Events />} />
              <Route path="/event/:id" element={<EventShell />} />
              <Route path="/createEvent" element={<CreateEvent />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/adminPanel" element={<AdminPanel />} />
              <Route path="/organizerHome" element={<OrganizerHome />} />
              <Route path="/organizerSettings" element={<OrganizerSettings />} />
              <Route path="/newCreateEvent" element={<NewCreateEvent />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/ticket" element={<Ticket />} />

              {/* <Route path="/createEvent" element={<TestCreateEvent />} /> */}
            </Routes>
          </>
        </>
      )}
    </>
  );
}

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
